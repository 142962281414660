import { IAccount, IOperationResultNoValue, UserRole } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import { parse as getQueryStringParams } from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../models/AppState';
import { EventLogger, IEventLoggingComponentProps, withEventLogging } from '../../../../models/Logging';
import { ISettingsChildComponentProps, withSettingsContext } from '../../../containers/Settings/context';
import { brandSecondary, mention } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { DeactivateAccount } from '../../DeactivateAccount';
import { LoadingSpinner } from '../../LoadingSpinner';
import { Modal } from '../../Modal';
import { RadioButton } from '../../RadioButton';
import { CalendarInCircleGraphic } from '../../svgs/graphics/CalendarInCircleGraphic';
import { EmailInCircleGraphic } from '../../svgs/graphics/EmailInCircleGraphic';
import { BillingIcon } from '../../svgs/icons/BillingIcon';
import { BlogIcon } from '../../svgs/icons/BlogIcon';
import { BusinessPlanPlaceholderIcon } from '../../svgs/icons/BusinessPlanPlaceholderIcon';
import { NotesPlaceholderIcon } from '../../svgs/icons/NotesPlaceholderIcon';
import { SvgIcon } from '../../svgs/icons/SvgIcon';
import { SettingsGroup } from '../SettingsGroup';
import { AdminSettingsSection } from '../models';
import { styleSheet } from '../styles';
import '../styles.less';
import { BlogSection, HandwrittenCardsSection } from './presentation';
import { SettingsAdminUsersGraphic } from '../../svgs/graphics/SettingsAdminUsersGraphic';

interface IProps
	extends RouteComponentProps<any>,
		IToasterComponentProps,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps,
		ISettingsChildComponentProps {
	className?: string;
	sectionToExpand?: AdminSettingsSection;
}

interface IState {
	accountForUpdate?: Partial<IAccount>;
	consumedUsers?: number;
	expandedSection?: AdminSettingsSection | null;
	showDeactivateModal?: boolean;
}

class _AdminSettings extends React.Component<IProps, IState> {
	private sectionRefs = {
		[AdminSettingsSection.Blog]: React.createRef<HTMLDivElement>(),
		[AdminSettingsSection.Cards]: undefined as React.RefObject<HTMLElement>,
		[AdminSettingsSection.Company]: undefined as React.RefObject<HTMLElement>,
		[AdminSettingsSection.Defaults]: undefined as React.RefObject<HTMLElement>,
		[AdminSettingsSection.Meetings]: undefined as React.RefObject<HTMLElement>,
		[AdminSettingsSection.UsersAndBilling]: undefined as React.RefObject<HTMLElement>,
	};

	constructor(props: IProps) {
		super(props);
		this.state = {
			accountForUpdate: null,
			consumedUsers: -1,
			showDeactivateModal: false,
			expandedSection: props.sectionToExpand,
		};
	}

	public componentDidMount() {
		const { userSession, history, location } = this.props;
		const { expandedSection } = this.state;

		const accountPromise = userSession.account.load(true);
		if (accountPromise) {
			accountPromise
				.then(() => {
					this.setAccountState(this.props.userSession.account);
				})
				.catch(() => this.displayErrorMessage('Could not load the account information'));
		}

		const userCountPromise = userSession.account.getUserCount();
		if (userCountPromise) {
			userCountPromise
				.then(count => this.setState({ consumedUsers: count }))
				.catch(() => this.displayErrorMessage('Could not get the number of users on this account'));
		}

		// look for command to auto-show deactivate modal
		const queryParams = getQueryStringParams(location.search) || {};
		const showDeactivateModal = queryParams.cmd === 'deactivate' ? true : false;

		this.setState(
			{
				showDeactivateModal,
			},
			() => {
				if (showDeactivateModal) {
					// remove query string
					history.replace(location.pathname);
				}
			}
		);

		if (expandedSection) {
			setTimeout(() => {
				this.sectionRefs[expandedSection]?.current?.scrollIntoView({ behavior: 'smooth' });
			}, 500);
		}
	}

	public render() {
		const { settingsContext, userSession } = this.props;
		const sectionsToRender = settingsContext?.supportedAdminSettingsSections || [
			AdminSettingsSection.Company,
			AdminSettingsSection.Defaults,
			AdminSettingsSection.Meetings,
			AdminSettingsSection.UsersAndBilling,
		];

		if (userSession.account.features.blogFeature?.enabled) {
			sectionsToRender.push(AdminSettingsSection.Blog);
		}

		if (userSession.account.features.handwrittenCards?.enabled) {
			sectionsToRender.push(AdminSettingsSection.Cards);
		}

		return (
			<div className='settings-admin-container'>
				{!!this.props.userSession.account.isBusy && <LoadingSpinner className='settings-admin-loader' type='large' />}
				{!this.props.userSession.account.isBusy && (
					<div className={css(styleSheet.root)}>
						{sectionsToRender.map(x => {
							return this.renderSection(x);
						})}
						<div className='settings-sectionHeader'>
							<span className='settings-sectionSubHeaderGray'>
								If you no longer wish to use Levitate, you may{' '}
								<button className='brand-link settings-deactive-text' onClick={this.toggleDeactivateModal(true)}>
									deactivate your account
								</button>
								.
							</span>
						</div>
					</div>
				)}
				<Modal
					isOpen={this.state.showDeactivateModal}
					onRequestClose={this.toggleDeactivateModal(false)}
					shouldCloseOnOverlayClick={true}
					useDefaultHeader={true}
				>
					<DeactivateAccount onCancel={this.toggleDeactivateModal(false)} onDeactivated={this.onAccountDeactivated} />
				</Modal>
			</div>
		);
	}

	private renderSection(section: AdminSettingsSection) {
		const { accountForUpdate, expandedSection } = this.state;
		const isExpanded = expandedSection === section;
		const settingsProps = this.getSettingsGroupProps(section);

		const planId =
			!!this.props.userSession && !!this.props.userSession.account && !!this.props.userSession.account.planDetails
				? this.props.userSession.account.planDetails.planId
				: 0;
		const ignoreInternalMeetings =
			!!accountForUpdate && !!accountForUpdate.preferences ? accountForUpdate.preferences.ignoreInternalMeetings : true;
		const defaultVisibility =
			!!accountForUpdate && !!accountForUpdate.preferences && !!accountForUpdate.preferences.defaults.visibility
				? accountForUpdate.preferences.defaults.visibility
				: 'all';
		const defaultUserRole =
			!!accountForUpdate && !!accountForUpdate.preferences && !!accountForUpdate.preferences.defaults.userRole
				? accountForUpdate.preferences.defaults.userRole
				: 'user';
		const sectionContent = (() => {
			switch (section) {
				case AdminSettingsSection.Company: {
					return (
						<div className='settings-admin-company-container' key={section}>
							<div className='settings-sectionHeader'>Company</div>
							<div className={css(baseStyleSheet.mb2)}>
								<span className={css(styleSheet.formFieldLabel)}>Company Name</span>
								<input
									type='text'
									id='editCompanyName'
									placeholder='Company Name (required)'
									className={css(baseStyleSheet.textFieldStandard)}
									value={this.state.accountForUpdate ? this.getField(this.state.accountForUpdate.companyName) : ''}
									onChange={this.updateFormFieldValue}
								/>
							</div>
							<div className={css(baseStyleSheet.mb2, baseStyleSheet.mt4, styleSheet.buttonContainer)}>
								<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.saveClick}>
									Save
								</button>
								<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={this.cancelClick}>
									Cancel
								</button>
							</div>
						</div>
					);
				}

				case AdminSettingsSection.Cards: {
					return <HandwrittenCardsSection key={section} />;
				}

				case AdminSettingsSection.Blog: {
					return (
						<div ref={this.sectionRefs[section]} key={section}>
							<BlogSection />
						</div>
					);
				}
				case AdminSettingsSection.Meetings: {
					return (
						<div className='settings-admin-meetings-container' key={section}>
							<div className='sectionHeader'>Meetings</div>
							<div className='settings-sectionSubHeaderGray'>
								Do you want reminders to record notes for internal meetings?
							</div>
							<div className='settings-radioButtonContainer'>
								<RadioButton
									checked={ignoreInternalMeetings}
									className='settings-radioButton'
									id='ignoreInternalMeetings'
									name='internalMeetingsRadioButtonGroup'
									onChange={this.internalMeetingRadioButtonChanged(true)}
								>
									Hide internal meetings
								</RadioButton>
								<RadioButton
									checked={!ignoreInternalMeetings}
									className='settings-radioButton'
									id='showInternalMeetings'
									name='internalMeetingsRadioButtonGroup'
									onChange={this.internalMeetingRadioButtonChanged(false)}
								>
									Show internal meetings
								</RadioButton>
							</div>
							<div className={css(baseStyleSheet.mb2, baseStyleSheet.mt4, styleSheet.buttonContainer)}>
								<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.saveClick}>
									Save
								</button>
								<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={this.cancelClick}>
									Cancel
								</button>
							</div>
						</div>
					);
				}
				case AdminSettingsSection.Defaults: {
					return (
						<div className='settings-admin-company-container' key={section}>
							<div className='settings-sectionHeader'>Defaults</div>
							<div className={css(baseStyleSheet.mb2)}>
								<span className='settings-sectionSubHeaderGray'>User Permission:</span>
								<RadioButton
									checked={defaultUserRole === 'user'}
									className='settings-radioButton'
									id='user'
									name='defaultUserRoleRadioButtonGroup'
									onChange={this.defaultUserRoleRadioButtionChanged('user')}
								>
									Regular
								</RadioButton>
								<RadioButton
									checked={defaultUserRole === 'limitedUser'}
									className='settings-radioButton'
									id='limitedUser'
									name='defaultUserRoleRadioButtonGroup'
									onChange={this.defaultUserRoleRadioButtionChanged('limitedUser')}
								>
									Limited (limited users would only be able to see his/her own contacts)
								</RadioButton>
							</div>
							<div className={css(baseStyleSheet.mb2)}>
								<span className='settings-sectionSubHeaderGray'>
									Visibility for new contacts, notes and action items
								</span>
								<RadioButton
									checked={defaultVisibility === 'all'}
									className='settings-radioButton'
									id='allVisibility'
									name='defaulVisibilityRadioButtionChanged'
									onChange={this.defaulVisibilityRadioButtionChanged('all')}
								>
									Shared with coworkers
								</RadioButton>
								<RadioButton
									checked={defaultVisibility !== 'all'}
									className='settings-radioButton'
									id='adminVisibility'
									name='defaulVisibilityRadioButtionChanged'
									onChange={this.defaulVisibilityRadioButtionChanged('admin')}
								>
									Private
								</RadioButton>
							</div>
							<div className={css(baseStyleSheet.mb2, baseStyleSheet.mt4, styleSheet.buttonContainer)}>
								<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.saveClick}>
									Save
								</button>
								<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={this.cancelClick}>
									Cancel
								</button>
							</div>
						</div>
					);
				}
				case AdminSettingsSection.UsersAndBilling: {
					return (
						<div className={planId > 0 ? 'settings-admin-users-container' : 'hide'} key={section}>
							<div className='sectionHeader'>Users &amp; Billing</div>
							<div className='settings-admin-users-innerContainer'>
								<figure className={css(styleSheet.billingIcon)}>
									<SettingsAdminUsersGraphic />
								</figure>
								<div className='settings-admin-users-infoContainer'>
									<div className='settings-admin-users-licenseCount'>{this.getLicenseInfo()}</div>
									<div className='settings-admin-users-contactSupport'>
										Please{' '}
										<a className='settings-contactSupportLink' href='mailto:support@levitateapp.com'>
											contact support
										</a>{' '}
										if you would like to add or remove users.
									</div>
									<div className='settings-admin-users-manage'>
										<span className='settings-manageUsersLink' onClick={this.manageUsersClick}>
											Manage users
										</span>
									</div>
								</div>
							</div>
						</div>
					);
				}
				default: {
					return null;
				}
			}
		})();
		return (
			<div onClick={() => this.setState({ expandedSection: isExpanded ? null : section })}>
				<SettingsGroup expanded={isExpanded} {...settingsProps}>
					{sectionContent}
				</SettingsGroup>
			</div>
		);
	}

	private displayErrorMessage = (errorMsg: string) => {
		if (this.props.errorMessages) {
			this.props.errorMessages.push({
				messages: [errorMsg],
			});
		}
	};

	private saveClick = () => {
		const updatedAccount = {
			...this.props.userSession.account.toJs(),
			...this.state.accountForUpdate,
		};

		const promise = this.props.userSession.account.save(updatedAccount);
		if (promise) {
			promise.then(this.saveSuccess).catch(this.saveError);
		}
	};

	private setAccountState = (account: IAccount) => {
		// Explicitly operate on a subset of properties from account.
		// We don't want intermediate updates to propagate outside of this context.
		const accountPartial = {
			companyName: account.companyName,
			preferences: { ...account.preferences },
		};

		if (!accountPartial.preferences.defaults) {
			accountPartial.preferences.defaults = {};
		}

		accountPartial.preferences.defaults = {
			...accountPartial.preferences.defaults,
		};

		this.setState({ accountForUpdate: accountPartial });
	};

	private saveSuccess = (account: IAccount) => {
		this.setAccountState(account);

		if (this.props.toaster) {
			this.props.toaster.push({
				message: 'Changes were saved successfully!',
				type: 'successMessage',
			});
		}
	};

	private saveError = (error: IOperationResultNoValue) => {
		this.displayErrorMessage(error.systemMessage);
	};

	private cancelClick = () => {
		// Redirect to dashboard
		this.props.history.push(`/dashboard`);
	};

	private manageUsersClick = () => {
		this.props.history.push(`/settings/admin/manage-users`);
	};

	private updateFormFieldValue = (e: any) => {
		const elementId = e.target.id;
		const account = this.state.accountForUpdate;
		switch (elementId) {
			case 'editCompanyName':
				account.companyName = e.target.value;
				break;
			default:
			// Do nothing
		}
		this.setState({ accountForUpdate: account });
	};

	private getField(field: string) {
		return field || '';
	}

	private internalMeetingRadioButtonChanged = (ignoreInternalMeetings: boolean) => () => {
		const accountForUpdate = this.state.accountForUpdate;

		if (accountForUpdate) {
			accountForUpdate.preferences.ignoreInternalMeetings = ignoreInternalMeetings;
			this.setState({ accountForUpdate });
		}
	};

	private defaultUserRoleRadioButtionChanged = (role: UserRole) => () => {
		const accountForUpdate = this.state.accountForUpdate;

		if (accountForUpdate) {
			accountForUpdate.preferences.defaults.userRole = role;
			this.setState({ accountForUpdate });
		}
	};

	private defaulVisibilityRadioButtionChanged = (visibility: 'admin' | 'all') => () => {
		const accountForUpdate = this.state.accountForUpdate;

		if (accountForUpdate) {
			accountForUpdate.preferences.defaults.visibility = visibility;
			this.setState({ accountForUpdate });
		}
	};

	private getTotalLicenses = () => {
		const planDetails =
			!!this.props.userSession.account && !!this.props.userSession.account.planDetails
				? this.props.userSession.account.planDetails
				: null;
		return planDetails ? planDetails.users : -1;
	};

	private getConsumedLicenses = () => {
		return this.state.consumedUsers ? this.state.consumedUsers : -1;
	};

	private getLicenseInfo = () => {
		const consumedUsers = this.getConsumedLicenses();
		const totalLicenses = this.getTotalLicenses();

		return consumedUsers > -1 && totalLicenses > -1 ? (
			<div>
				{/* @ts-ignore */}
				You have used <strong>{consumedUsers.toString()}</strong> of {totalLicenses.toString()} licenses.
			</div>
		) : (
			<div>Loading user license information...</div>
		);
	};

	private toggleDeactivateModal = (show: boolean) => () => {
		if (show) {
			this.props.history.replace(`/settings/admin/deactivate-account`);
			EventLogger.logInput('AdminSettings', 'Deactivate', 'Click');
		} else {
			this.props.history.replace(`/settings/admin`);
			EventLogger.logInput('AdminSettings', 'Deactivate-Cancel', 'Click');
		}
		this.setState({
			showDeactivateModal: show,
		});
	};

	private onAccountDeactivated = () => {
		this.props.history.push('/logout');
	};
	private getSettingsGroupProps(section: AdminSettingsSection) {
		switch (section) {
			case AdminSettingsSection.Blog:
				return {
					name: 'Blog Settings',
					description: 'Manage blog settings.',
					icon: (
						<SvgIcon height={60} width={60}>
							<g fill='none' fillRule='evenodd'>
								<circle cx='30' cy='30' r='30' fill={mention} />
								<g transform='translate(18 15)'>
									<BlogIcon fill={brandSecondary} width={26} height={26} />
								</g>
							</g>
						</SvgIcon>
					),
				};
			case AdminSettingsSection.Company:
				return {
					name: 'Company Settings',
					description: 'Manage company information.',
					icon: (
						<BusinessPlanPlaceholderIcon strokeColor={brandSecondary} bgCircleFill={mention} width={60} height={60} />
					),
				};
			case AdminSettingsSection.Cards:
				return {
					name: 'Cards Settings',
					description: 'Configure defaults settings for cards.',
					icon: <EmailInCircleGraphic width={60} height={60} strokeColor={brandSecondary} bgCircleFill={mention} />,
				};
			case AdminSettingsSection.Defaults:
				return {
					name: 'Default Settings',
					description: 'Set and manage default settings.',
					icon: <NotesPlaceholderIcon width={60} height={60} strokeColor={brandSecondary} bgCircleFill={mention} />,
				};
			case AdminSettingsSection.Meetings:
				return {
					name: 'Meetings Settings',
					description: 'Adjust meeting preferences and settings.',
					icon: <CalendarInCircleGraphic width={60} height={60} strokeColor={brandSecondary} bgCircleFill={mention} />,
				};
			case AdminSettingsSection.UsersAndBilling:
				return {
					name: 'Users & Billing',
					description: 'Manage user accounts, billing information, and subscription plans.',
					icon: (
						<SvgIcon height={60} width={60}>
							<g fill='none' fillRule='evenodd'>
								<circle cx='30' cy='30' r='30' fill={mention} />
								<BillingIcon width={60} height={60} />
							</g>
						</SvgIcon>
					),
				};
			default:
				return {
					name: '',
					description: '',
					icon: null,
				};
		}
	}
}

const ObservableAdminSettings = observer(_AdminSettings);
const AdminSettingsWithContext = inject(
	ToasterViewModelKey,
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey
)(ObservableAdminSettings);
const AdminSettingsWithRouter = withRouter(AdminSettingsWithContext);
export const AdminSettings = withSettingsContext(withEventLogging(AdminSettingsWithRouter, 'AdminSettings'));
