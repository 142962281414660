// eslint-disable-next-line import/no-internal-modules
import * as Api from '@ViewModels';
import { yupResolver } from '@hookform/resolvers/yup';
import { css } from 'aphrodite';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useToaster, useUserSession } from '../../../../models/hooks/appStateHooks';
import { useHandwrittenCardAccountPreferencesMutation } from '../../../../queries';
import { useCurrentUserGiftingBalance } from '../../../containers/PostcardComposer/hooks/useCurrentUserGiftingBalance';
import { baseStyleSheet } from '../../../styles/styles';
import { Select } from '../../Select';
import { TextInput } from '../../TextInput';
import { CountrySelector } from '../../ValueSelectors/CountrySelector';
import { INameValue } from '../../ValueSelectors/ValueSelector';
import { handwritingStyleFontMap } from '../PersonalSettings/HandwrittenCardSettings';
import { PurchaseCreditsModal } from '../PurchaseCreditsModal';
import { styleSheet } from './styles';

const handwritingStyleOptions = Object.values(Api.HandwritingStyle).map(value => ({
	dataContext: value,
	id: `handwriting-style-option-${value}`,
	text: value,
}));

const validationSchema = yup.object({
	address1: yup.string().required('Address line 1 is required'),
	address2: yup.string(),
	city: yup.string().required('City is required'),
	country: yup.string().required('Country is required'),
	firstName: yup.string(),
	handwritingStyle: yup.string().oneOf(Object.values(Api.HandwritingStyle)),
	lastName: yup.string(),
	postalCode: yup.string().required('Postal Code is required'),
	stateProvince: yup.string().required('State/Province is required'),
});

export const HandwrittenCardsSection = () => {
	const userSession = useUserSession();
	const history = useHistory();
	const previewInputRef = React.useRef<HTMLInputElement>(null);
	const toaster = useToaster();
	const initialReturnContact = userSession.account?.preferences?.handwrittenCardPreferences?.returnContact ?? {};
	const isCompAccount = userSession.account?.features?.handwrittenCards?.isCompAccount;
	const [isPurchaseCreditsModalOpen, setPurchaseCreditsModalOpen] = React.useState(false);
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('HandwrittenCardAccountSettings');
	const { currentBalanceFormatted, cardsAvailable, isLoading } = useCurrentUserGiftingBalance();
	const preferencesMutation = useHandwrittenCardAccountPreferencesMutation({
		onError: (error: Api.IOperationResultNoValue) => {
			// @ts-ignore
			errorMessages.pushApiError(error);
			logApiError('MutateHandwrittenCardAccountSettings-Error', error);
		},
		onSuccess: () => {
			// @ts-ignore
			toaster.push({
				message: 'Handwritten card changes saved successfully',
				type: 'successMessage',
			});
		},
	});

	const { handleSubmit, control, formState, getValues, watch } = useForm({
		defaultValues: {
			address1: initialReturnContact?.address?.address1 ?? '',
			address2: initialReturnContact?.address?.address2 ?? '',
			city: initialReturnContact?.address?.city ?? '',
			country: initialReturnContact?.address?.country ?? '',
			handwritingStyle:
				userSession.account?.preferences?.handwrittenCardPreferences?.handwritingStyle ?? Api.HandwritingStyle.Aladdin,
			postalCode: initialReturnContact?.address?.postalCode ?? '',
			stateProvince: initialReturnContact?.address?.stateProvince ?? '',
		},
		resolver: yupResolver(validationSchema),
	});
	const watchHandwritingStyle = watch('handwritingStyle');

	React.useEffect(() => {
		const previewInput = previewInputRef.current;
		if (previewInput) {
			previewInput.style.fontFamily = handwritingStyleFontMap[watchHandwritingStyle].fontFamily;
			previewInput.style.fontSize = `${handwritingStyleFontMap[watchHandwritingStyle].fontSize}px`;
		}
	}, [watchHandwritingStyle]);

	const handleSave = () => {
		const formValues = getValues();
		preferencesMutation.mutate({
			handwritingStyle: formValues.handwritingStyle,
			returnContact: {
				address: {
					address1: formValues.address1,
					address2: formValues.address2,
					city: formValues.city,
					country: formValues.country,
					postalCode: formValues.postalCode,
					stateProvince: formValues.stateProvince,
				},
			},
		});
	};

	return (
		<form className='settings-admin-company-container' onSubmit={handleSubmit(handleSave)}>
			<div className='settings-sectionHeader'>Cards</div>

			<div className={css(styleSheet.fieldContainerStandard)}>
				<span className={css(styleSheet.formFieldLabel)}>Return Address</span>
				<div>
					<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='hwc-admin-setting-address1-input'>
						Address Line 1
					</label>
					<Controller
						name='address1'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<TextInput inputId='hwc-admin-setting-address1-input' type='text' inputRef={ref} {...fieldProps} />
						)}
					/>
					{formState.errors.address1 != null ? (
						<p role='alert' className={css(styleSheet.formErrorLine)}>
							{formState.errors.address1.message}
						</p>
					) : null}
					<label className={css(styleSheet.label)} htmlFor='hwc-admin-setting-address2-input'>
						Address Line 2
					</label>
					<Controller
						name='address2'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<TextInput inputId='hwc-admin-setting-address2-input' type='text' inputRef={ref} {...fieldProps} />
						)}
					/>
					<div className={css(styleSheet.addresslineGroup)}>
						<div className={css(styleSheet.addresslineCol)}>
							<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='hwc-admin-setting-city-input'>
								City
							</label>
							<Controller
								name='city'
								control={control}
								render={({ field: { ref, ...fieldProps } }) => (
									<TextInput inputId='hwc-admin-setting-city-input' type='text' inputRef={ref} {...fieldProps} />
								)}
							/>
							{formState.errors.city != null ? (
								<p role='alert' className={css(styleSheet.formErrorLine)}>
									{formState.errors.city.message}
								</p>
							) : null}
						</div>
						<div className={css(styleSheet.addresslineCol)}>
							<label
								className={css(styleSheet.label, styleSheet.labelRequired)}
								htmlFor='hwc-admin-setting-stateProvince-input'
							>
								State/Province
							</label>
							<Controller
								name='stateProvince'
								control={control}
								rules={{ required: true }}
								render={({ field: { ref, ...fieldProps } }) => (
									<TextInput
										inputId='hwc-admin-setting-stateProvince-input'
										type='text'
										inputRef={ref}
										{...fieldProps}
									/>
								)}
							/>
							{formState.errors.stateProvince != null ? (
								<p role='alert' className={css(styleSheet.formErrorLine)}>
									{formState.errors.stateProvince.message}
								</p>
							) : null}
						</div>
						<div className={css(styleSheet.addresslineCol)}>
							<label
								className={css(styleSheet.label, styleSheet.labelRequired)}
								htmlFor='hwc-admin-setting--postalCode-input'
							>
								Zip/Postal Code
							</label>
							<Controller
								name='postalCode'
								control={control}
								rules={{ required: true }}
								render={({ field: { ref, ...fieldProps } }) => (
									<TextInput inputId='hwc-admin-setting-postalCode-input' type='text' inputRef={ref} {...fieldProps} />
								)}
							/>
							{formState.errors.postalCode != null ? (
								<p role='alert' className={css(styleSheet.formErrorLine)}>
									{formState.errors.postalCode.message}
								</p>
							) : null}
						</div>
					</div>
					<label className={css(styleSheet.label, styleSheet.labelRequired)} htmlFor='hwc-admin-setting-country-input'>
						Country
					</label>
					<Controller
						name='country'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<CountrySelector
								onSelectionChanged={(countrySelection: INameValue) => {
									const selectedCountry = countrySelection.value !== 'None' ? countrySelection.value : '';
									fieldProps.onChange(selectedCountry);
								}}
								selectedValue={fieldProps.value}
							/>
						)}
					/>
					{formState.errors.country != null ? (
						<p role='alert' className={css(styleSheet.formErrorLine)}>
							{formState.errors.country.message}
						</p>
					) : null}
				</div>
			</div>

			<div className={css(styleSheet.fieldContainerStandard)}>
				<span className={css(styleSheet.formFieldLabel)}>Writing Style</span>
				<div>
					<Controller
						name='handwritingStyle'
						control={control}
						render={({ field: { ref, ...fieldProps } }) => (
							<Select
								styles={[styleSheet.formInput]}
								options={handwritingStyleOptions}
								onOptionClick={option => {
									fieldProps.onChange(option.dataContext);
								}}
								selectedOption={handwritingStyleOptions.find(option => option.dataContext === fieldProps.value)}
							/>
						)}
					/>
					<TextInput
						inputClassName={css(styleSheet.formPreviewInput)}
						className={css(styleSheet.formInputPreview)}
						disabled={true}
						type='text'
						inputId='hwc-style-example'
						value='The quick brown fox jumped over the lazy dog'
						inputRef={previewInputRef}
					/>
				</div>
			</div>

			<div className={css(styleSheet.fieldContainerStandard)}>
				<span className={css(styleSheet.formFieldLabel)}>Need Funds?</span>
				<div>
					<span className={css(styleSheet.label)}>
						{isLoading ? (
							'Loading current balance...'
						) : (
							<>
								Current balance: <strong>{currentBalanceFormatted}</strong> / Cards Available:{' '}
								<strong>{cardsAvailable}</strong>
							</>
						)}
					</span>
					{!isCompAccount ? (
						<button
							className={css(styleSheet.purchaseButton, baseStyleSheet.ctaButtonSmall)}
							onClick={ev => {
								ev.preventDefault();
								setPurchaseCreditsModalOpen(true);
							}}
						>
							Purchase Credits
						</button>
					) : null}
				</div>
				<PurchaseCreditsModal
					isOpen={isPurchaseCreditsModalOpen}
					onRequestClose={() => {
						setPurchaseCreditsModalOpen(false);
					}}
				/>
			</div>

			<div className={css(baseStyleSheet.mb2, baseStyleSheet.mt4, styleSheet.buttonContainer)}>
				<button className={css(baseStyleSheet.ctaButtonSmall)} type='submit' disabled={formState.isSubmitting}>
					Save
				</button>
				<button className={css(baseStyleSheet.ctaButtonReverseSmall)} onClick={() => history.push('/dashboard')}>
					Cancel
				</button>
			</div>
		</form>
	);
};

export const BlogSection = () => {
	return (
		<section>
			<p>
				For a more complete setup guide, please visit our{' '}
				<a href='https://help.levitate.ai/article/359-blogs' target='_blank' rel='noreferrer'>
					KB article
				</a>
				.
			</p>
		</section>
	);
};
