import { StyleSheet } from 'aphrodite';
import {
	background,
	brandPrimary,
	brandPrimaryActive,
	brandSecondary,
	darkGrayFontColor,
	header,
	navigation,
	titles,
	white,
} from '../../styles/colors';
import { BreakPoints, BrowserMediaQueries, CssSelectors, truncateTextStyle } from '../../styles/styles';

export const AppBarHeight = 96;

const searchSectionHeight = 60;

export const styleSheet = StyleSheet.create({
	addCreditBtn: {
		maxWidth: 146,
		width: '100%',
	},
	appBar: {
		borderBottom: 'none',
	},
	appBarContent: {
		alignItems: 'center',
		background: white,
		boxSizing: 'border-box',
		display: 'flex',
		height: '100%',
		justifyContent: 'space-between',
		position: 'relative',
	},
	blogPostWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		paddingLeft: 0,
		paddingTop: 0,
		width: '100%',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',

		[BreakPoints.forMinWidth(1280)]: {
			...CssSelectors.allChildren(
				{
					marginRight: 0,
				},
				':nth-child(3n)'
			),
		},
	},
	balanceAvailable: {
		color: navigation,
	},
	browseAllCampaigns: {
		cursor: 'pointer',
		marginRight: 30,
		marginTop: 0,
		textAlign: 'right',
	},
	browseAllFeatured: {
		cursor: 'pointer',
		marginRight: 20,
		marginTop: 0,
		textAlign: 'right',
	},
	cardList: {
		gridTemplateColumns: 'repeat(auto-fill, minmax(min(342px, 100%), 1fr))',
		height: 'calc(100% - 66px)',
	},
	cardListEmpty: {
		color: brandPrimaryActive,
		fontSize: 14,
		padding: 20,
		textAlign: 'center',
	},
	cards: {
		background,
		boxSizing: 'border-box',
		paddingBottom: 60,
	},
	cardsAvailable: {
		color: brandSecondary,
	},
	cardSection: {
		...CssSelectors.allChildren(
			{
				marginTop: 15,
			},
			'button'
		),
		background,
		padding: '20px 30px 20px 30px',
	},
	cardSectionRow: {
		alignItems: 'stretch',
		display: 'flex',
		flexWrap: 'wrap',
		margin: '20px 0px 0px 0px',
	},
	categoryTitle: {
		color: titles,
		marginRight: 30,
		paddingBottom: 4,
		paddingLeft: 5,
		textTransform: 'capitalize',
	},
	container: {
		background: white,
		height: '100%',
		width: 'calc(100% - 40px)',
	},
	flexDiv: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	htmlEmpty: {
		paddingTop: 48,
		width: '100%',
	},
	htmlEmptyText: {
		textAlign: 'center',
		margin: 0,
	},
	htmlNewsletterTab: {
		border: `1px solid #C75DA6`,
		borderRadius: 3,
		boxSizing: 'border-box',
		flexShrink: 0,
		padding: '5px 10px',
	},
	htmlNewsletterTemplates: {},
	loadingContainer: {
		marginTop: 30,
	},
	newCampaignButton: {
		flexGrow: 1,
		marginLeft: 12,
		maxHeight: searchSectionHeight,
		maxWidth: 350,
		[BrowserMediaQueries.IE11]: {
			flexGrow: 0,
		},
	},
	newTabIndicator: {
		background: `linear-gradient(60deg, ${brandSecondary} 0%, #00D5F5 100%)`,
		border: `1px solid ${brandSecondary}`,
		borderRadius: 10,
		color: 'white',
		display: 'block',
		fontSize: 10,
		height: 'auto !important',
		marginTop: -10,
		position: 'absolute',
		right: '0.875rem',
		textTransform: 'capitalize',
		top: 0,
		width: '40px !important',
	},
	socialDraftCards: {
		gridTemplateColumns: 'repeat(auto-fill, minmax(min(288px, 100%), 1fr))',
	},
	noCampaigns: {
		height: 500,
		marginTop: 60,
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				color: '#4a4a4a',
				fontSize: 14,
				margin: '10px 0',
			},
			'p'
		),
	},
	pageLoading: {
		marginTop: 30,
	},
	reviewed: {
		color: '#89C947',
		marginLeft: 5,
	},
	searchBox: {
		alignItems: 'center',
		background: white,
		border: '1px solid #CCCCCC',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: '#4a4a4a',
		display: 'flex',
		fontSize: 14,
		height: 35,
		width: 200,
		...CssSelectors.allDescendants(
			{
				width: 170,
			},
			'input'
		),
	},
	searchBoxInput: {
		marginLeft: 10,
		width: '88%',
		...truncateTextStyle,
	},
	searchKeywords: {
		flex: 1,
	},
	sortBySelect: {
		height: 33,
	},
	sortBySelectContainer: {
		maxWidth: 160,
	},
	sortBySelector: {
		alignItems: 'center',
		display: 'flex',
		marginRight: 20,
		whiteSpace: 'nowrap',
		...CssSelectors.allChildren(
			{
				color: darkGrayFontColor,
				fontSize: 14,
				marginRight: 10,
			},
			':first-child'
		),
		...CssSelectors.allDescendants(
			{
				height: 35,
				paddingTop: 14,
				width: 160,
			},
			'.select-trigger'
		),
	},
	tabContainer: {
		position: 'relative',
	},
	tabFlag: {
		display: 'flex',
		marginTop: 6,
		position: 'absolute',
		right: 0,
		top: 0,
		transform: 'translate(0, -50%)',
		...CssSelectors.allChildren(
			{
				borderTopRightRadius: 3,
			},
			'svg'
		),
	},
	tabFlagSelected: {
		top: -1,
	},
	tabView: {
		height: '100%',
	},
	tabViewContent: {
		display: 'flex',
		height: 'calc(100% - 64px)',
		overflow: 'auto',
	},
	tabViewItem: {
		':focus-visible': {
			background: white,
			border: `1px solid ${brandPrimary}`,
			borderBottom: 'none',
		},
		':hover': {
			background: '#ddd5d533',
		},
		'@media (max-width: 1072px)': {
			width: 96,
		},
		background: '#ddd5d552',
		border: `1px solid transparent`,
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		color: header,
		display: 'inline-flex',
		flexBasis: 'auto',
		flexGrow: 0,
		fontSize: 14,
		height: '100%',
		marginLeft: 1,
		position: 'relative',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
		width: 109,
	},
	tabViewSelectedItem: {
		':hover': {
			background: white,
		},
		background: white,
		border: `1px solid #d3dbde`,
		borderBottom: 'none',
		boxSizing: 'border-box',
		overflow: 'visible',
		position: 'relative',
	},
	tabViewSelectedTabIndicator: {
		display: 'none',
	},
	tabViewTablist: {
		background: 'white',
		boxSizing: 'border-box',
		display: 'flex',
		height: 64,
		justifyContent: 'space-between',
		paddingLeft: 32,
		paddingRight: 32,
		paddingTop: 16,
		[BreakPoints.forMinWidth(640)]: {
			paddingLeft: 64,
		},
		[BreakPoints.forMinWidth(1024)]: {
			paddingLeft: 128,
		},
	},
	tabViewTabs: {
		height: '100%',
		display: 'flex',
	},
	templateCard: {
		':last-child': {
			marginRight: 0,
		},
		[BreakPoints.forMaxWidth(1150)]: {
			width: '31%',
		},
		marginRight: 20,
		width: '32%',
	},
});
