import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { getRatingIntFromText } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import {
	invalidateGetGoogleBusinessReview,
	invalidateInfiniteReviews,
	useGetGoogleBusinessReview,
	usePostGoogleBusinessReply,
} from '../../../../queries';
import { Avatar2 } from '../../../../web/components/Avatar2';
import { LoadingSpinner } from '../../../../web/components/LoadingSpinner';
import { TextArea } from '../../../../web/components/TextArea';
import { AIGoogleReviewResponse } from '../../../../web/components/ai/AIGoogleReviewResponse';
import { LocationPinIcon } from '../../../../web/components/svgs/icons/LocationPinIcon';
import { brandPrimary, white } from '../../../../web/styles/colors';
import { baseStyleSheet } from '../../../../web/styles/styles';
import WorkingLionImageUrl from '../../../assets/aiAssistantThinking.png';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { Modal } from '../../Modal';
import { StarRating } from '../../surveys/StarRating';
import { styleSheet } from './styles';

interface IProps {
	isOpen: boolean;
	reviewId: string;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AIThinkingOverlay = ({
	generationError,
	clearError,
}: {
	generationError: boolean;
	clearError: () => void;
}) => {
	return (
		<div className={css(styleSheet.thinkingOverlay)}>
			<div className={css(styleSheet.thinkingLionImage)}>
				<img src={WorkingLionImageUrl} />
			</div>
			<div className={css(styleSheet.thinkingModalMessages)}>
				{!generationError ? (
					<>
						<p className={css(styleSheet.pill)}>Putting the final touches on that response...</p>

						<div className={css(styleSheet.thinking)}>
							<span />
							<span />
							<span />
						</div>
					</>
				) : null}

				{generationError ? (
					<>
						<p className={css(styleSheet.pill)}>There was an error generating content...</p>

						<button className={css(baseStyleSheet.ctaButton)} onClick={clearError}>
							<span>Retry</span>
						</button>
					</>
				) : null}
			</div>
		</div>
	);
};

export const GoogleBusinessReplyModal = ({ isOpen, reviewId, setIsOpen }: IProps) => {
	const userSession = useUserSession();
	const [response, setResponse] = useState('');
	const [isGeneratingContent, setIsGeneratingContent] = useState(false);
	const [generationError, setGenerationError] = useState(false);

	const isAiEnabled = userSession.account?.features?.contentGeneration?.enabled;

	const { data, status } = useGetGoogleBusinessReview({
		enabled: isOpen,
		reviewId,
	});

	const onSuccess = () => {
		setIsOpen(false);
		setResponse('');
		invalidateGetGoogleBusinessReview(reviewId);
		invalidateInfiniteReviews();
	};

	const onError = () => {
		setGenerationError(true);
	};

	const clearError = () => {
		setIsGeneratingContent(false);
		setGenerationError(null);
	};

	const postGoogleBusinessReplyMutation = usePostGoogleBusinessReply({
		onSuccess,
	});

	const onPostReplyClicked = () => {
		postGoogleBusinessReplyMutation.reset();
		postGoogleBusinessReplyMutation.mutate({
			comment: response,
			reviewId,
		});
	};

	const onCancel = () => {
		setResponse('');
		invalidateGetGoogleBusinessReview(reviewId);
		setIsOpen(false);
	};

	const onTextChanged = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setResponse(event.target.value || '');
	};

	const getDaysAgoText = (value: any) => {
		return moment(value).fromNow();
	};

	if (!isOpen || !data) {
		return null;
	}

	const getInitial = (value: string) => {
		return value.substring(0, 1);
	};

	const isLoaded = status === 'success';
	const isReplying = postGoogleBusinessReplyMutation.status === 'loading';
	const rating = getRatingIntFromText(data.rating);
	const reviewText = data.text || '';
	const totalStars = 5;

	const showOverlay = isGeneratingContent || !!generationError;

	return (
		<Modal isOpen={isOpen} shouldCloseOnOverlayClick={false} className={css(styleSheet.modal)}>
			<div className={css(styleSheet.container)}>
				{showOverlay ? <AIThinkingOverlay generationError={generationError} clearError={clearError} /> : null}

				<section className={showOverlay ? css(baseStyleSheet.hidden) : ''}>
					<div className={css(styleSheet.header)}>
						<span>Google Review Response</span>
						<DeprecatedCloseButton onClick={onCancel} className={css(styleSheet.closeButton)} fillColor={white} />
					</div>

					{!isLoaded ? <LoadingSpinner type='small' /> : null}

					{isLoaded ? (
						<div className={css(styleSheet.body)}>
							<div className={css(styleSheet.reviewRow)}>
								<article className={css(styleSheet.reviewerColumn)}>
									<div className={css(styleSheet.reviewer)}>
										<Avatar2
											fallbackText={getInitial(data.reviewer.name)}
											imgSrc={data.reviewer.profilePhotoUrl}
											styleDeclaration={[styleSheet.avatar]}
										/>

										<div className={css(styleSheet.author)}>
											<strong className={css(styleSheet.reviewerName)}>{data.reviewer.name}</strong>

											<em className={css(styleSheet.timeAgo)}>{getDaysAgoText(data.updateTime)}</em>
										</div>
									</div>

									<div className={css(styleSheet.location)}>
										<LocationPinIcon className={css(styleSheet.locationIcon)} fillColor={brandPrimary} />
										{data.locationDescription}
									</div>
								</article>

								<article className={css(styleSheet.reviewColumn)}>
									<div className={css(styleSheet.rating)}>
										<StarRating
											readonly
											fillColor='#F3CE0D'
											total={totalStars}
											value={rating}
											starStyles={[styleSheet.ratingStar]}
										/>
									</div>

									<p className={css(styleSheet.reviewText)}>{reviewText}</p>
								</article>
							</div>

							<div className={css(styleSheet.responseRow)}>
								<section className={css(styleSheet.responseContainer)}>
									<article className={css(styleSheet.nonAiResponse)}>
										<h3 className={css(styleSheet.responseTitle)}>New Response</h3>

										<TextArea
											inputId='create-google-review-reply-input'
											value={response}
											onChange={onTextChanged}
											rows={9}
										/>

										{postGoogleBusinessReplyMutation.isError ? (
											<div className={css(styleSheet.errorContainer)}>
												There was an issue submitting your response. You can try again, or reach out to support.
											</div>
										) : null}

										{isReplying ? <LoadingSpinner type='small' /> : null}

										{!isReplying ? (
											<div className={css(styleSheet.buttonContainer)}>
												<button
													className={css(baseStyleSheet.ctaButton)}
													onClick={onPostReplyClicked}
													disabled={!response}
												>
													Post My Response
												</button>

												<button
													className={css(baseStyleSheet.ctaButtonReverse)}
													onClick={onCancel}
													disabled={isReplying}
												>
													Cancel
												</button>
											</div>
										) : null}
									</article>
								</section>

								{isAiEnabled ? (
									<section className={css(styleSheet.aiResponseContainer)}>
										<AIGoogleReviewResponse
											review={data}
											onGeneratingChanged={(value: boolean) => setIsGeneratingContent(value)}
											onPostSuccess={() => onSuccess()}
											onError={onError}
										/>
									</section>
								) : null}
							</div>
						</div>
					) : null}
				</section>
			</div>
		</Modal>
	);
};
