import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { IContactsSelfImportRequest, ILocationState } from '../../../../models';
import { numberToCurrencyStringValue } from '../../../../models/UiUtils';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import {
	invalidateInsurancePolicyFilter,
	invalidateInsurancePolicySummary,
	useExportInsurancePoliciesMutation,
	useInsurancePolicyContactsMutation,
	useInsurancePolicyFilter,
	useInsurancePolicySummaryQuery,
} from '../../../../queries/';

import { DeleteModal, DeleteType } from '../DeleteModal/';
import { styleSheet as dbStyleSheet } from '../styles';
import { EditPolicyModal } from './EditPolicyModal/';
import { styleSheet } from './styles';

import { useDebounceValue } from '../../../../hooks/useDebounceValue';
import { useEventLogging } from '../../../../models/Logging';
import { Checkbox } from '../../../components/Checkbox';
import { dataGridStyles } from '../../../components/DataGrid/data-grid-styles';
import { ExportConfirmationModalV2 } from '../../../components/ExportConfirmation';
import { FabContext } from '../../../components/FabContext';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MoreMenu, MoreMenuItem } from '../../../components/MoreMenu';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../../components/NavigationBreadcrumbsBar';
import { ISelectOption, Select } from '../../../components/Select';
import { TextInput } from '../../../components/TextInput';
import { TinyPopover } from '../../../components/TinyPopover';
import { UsersActionBar } from '../../../components/UsersActionBar';
import { SimpleAutoCompleteSearchField } from '../../../components/autocomplete/SimpleAutoCompleteSearchField';
import { Badge } from '../../../components/dataBoards/Badge';
import { PolicyCarrierSearch } from '../../../components/dataBoards/policies/PolicyCarrierSearch';
import { PolicyLineOfBusinessFilterDropdown } from '../../../components/dataBoards/policies/PolicyLineOfBusinessFilterDropdown';
import { PremiumFilterDropdown } from '../../../components/dataBoards/policies/PremiumFilterDropdown';
import { RenewalDateFilterDropdown } from '../../../components/dataBoards/policies/RenewalDateFilterDropdown';
import { PolicyPropertyOption } from '../../../components/dataBoards/policies/models';
import { DropDownIcon } from '../../../components/svgs/icons/DropDownIcon';
import { EmptySearchIcon } from '../../../components/svgs/icons/EmptySearchIcon';
import { SearchIcon } from '../../../components/svgs/icons/SearchIcon';
import { white } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { PolicyHolderDetailsFlyout } from './PolicyHolderDetailsFlyout';

export const PolicyPropertyOptions = Object.keys(PolicyPropertyOption).map(key => {
	const option: ISelectOption = {
		id: key,
		text: PolicyPropertyOption[key as keyof typeof PolicyPropertyOption],
		dataContext: key,
	};
	return option;
});

enum MenuItem {
	Edit = 'Edit',
	Delete = 'Delete',
}

const defaultRenewalStartDate = moment().toDate();
const defaultRenewalEndDate = moment().endOf('month').toDate();

export function PoliciesBoard() {
	const fullscreenModal = useFullscreenModal();
	const { logApiError } = useEventLogging('PoliciesBoard');

	const [selectionState, setSelectionState] = React.useState<Api.ESelectionState>(Api.ESelectionState.None);
	const [selectedPolicyRows, setSelectedPolicyRows] = React.useState<string[]>([]);
	const [excludedContacts, setExcludedContacts] = React.useState<Api.IProjectedContact[]>([]);

	const [selectedSearchProperty, setSelectedSearchProperty] = React.useState(PolicyPropertyOptions[0]);
	const [searchFieldValue, setSearchFieldValue] = React.useState('');
	const debouncedSearchFieldValue = useDebounceValue(searchFieldValue, 500);
	const [selectedCarrierId, setSelectedCarrierId] = React.useState<string>(null);

	const [minimumPremium, setMinimumPremium] = React.useState<string>('');
	const [maximumPremium, setMaximumPremium] = React.useState<string>('');

	const [selectedMinimumRenewalDate, setSelectedMinimumRenewalDate] = React.useState<Date>(defaultRenewalStartDate);
	const [selectedMaximumRenewalDate, setSelectedMaximumRenewalDate] = React.useState<Date>(defaultRenewalEndDate);

	const [policyToEdit, setPolicyToEdit] = React.useState<Api.IInsurancePolicy>(null);
	const [policyToDelete, setPolicyToDelete] = React.useState<Api.IInsurancePolicy>(null);

	const [isAddPopoverOpen, setIsAddPopoverOpen] = React.useState(false);
	const [isEditOpen, setIsEditOpen] = React.useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
	const [isDetailsOpen, setIsDetailsOpen] = React.useState(false);

	const [contactToView, setContactToView] = React.useState<Api.IContact>(null);

	const [selectedContact, setSelectedContact] = React.useState<Api.IContact>(null);
	const [selectedOwner, setSelectedOwner] = React.useState<Api.IContact>(null);

	const [selectedPolicyLineOfBusinessIds, setSelectedPolicyLineOfBusinessIds] = React.useState<string[]>([]);

	const locationName = 'Policies Board';
	const hasSearchValues =
		!!debouncedSearchFieldValue ||
		selectedContact ||
		selectedOwner ||
		selectedPolicyLineOfBusinessIds.length > 0 ||
		selectedCarrierId ||
		minimumPremium ||
		maximumPremium ||
		selectedMinimumRenewalDate ||
		selectedMaximumRenewalDate;

	const policyFilterCriteria = React.useMemo(() => {
		const criterias: Api.IInsurancePolicyFilterCriteria[] = [];

		if (debouncedSearchFieldValue) {
			criterias.push({
				property: Api.InsurancePolicyFilterProperty.PolicyNumber,
				value: debouncedSearchFieldValue,
			});
		}

		if (selectedContact) {
			criterias.push({
				property: Api.InsurancePolicyFilterProperty.Contact,
				value: selectedContact.id,
			});
		}

		if (selectedOwner) {
			criterias.push({
				property: Api.InsurancePolicyFilterProperty.OwnerId,
				value: selectedOwner.id,
			});
		}

		if (!!minimumPremium || !!maximumPremium) {
			if (minimumPremium) {
				criterias.push({
					property: Api.InsurancePolicyFilterProperty.Premium,
					op: Api.FilterOperator.Gt,
					value: minimumPremium,
				});
			}
			if (maximumPremium) {
				criterias.push({
					property: Api.InsurancePolicyFilterProperty.Premium,
					op: Api.FilterOperator.Lt,
					value: maximumPremium,
				});
			}
		}

		if (!!selectedMinimumRenewalDate || !!selectedMaximumRenewalDate) {
			if (selectedMinimumRenewalDate) {
				criterias.push({
					property: Api.InsurancePolicyFilterProperty.RenewalDate,
					op: Api.FilterOperator.Gt,
					value: moment(selectedMinimumRenewalDate).format('YYYY-MM-DD'),
				});
			}
			if (selectedMaximumRenewalDate) {
				criterias.push({
					property: Api.InsurancePolicyFilterProperty.RenewalDate,
					op: Api.FilterOperator.Lt,
					value: moment(selectedMaximumRenewalDate).format('YYYY-MM-DD'),
				});
			}
		}
		if (selectedPolicyLineOfBusinessIds.length > 0) {
			criterias.push({
				criteria: selectedPolicyLineOfBusinessIds.map(policyLineOfBusinessId => {
					return {
						property: Api.InsurancePolicyFilterProperty.LineOfBusiness,
						value: policyLineOfBusinessId,
					};
				}),
				op: Api.FilterOperator.Or,
			});
		}

		if (selectedCarrierId) {
			criterias.push({
				property: Api.InsurancePolicyFilterProperty.Carrier,
				value: selectedCarrierId,
			});
		}

		return criterias;
	}, [
		maximumPremium,
		minimumPremium,
		selectedMinimumRenewalDate,
		selectedMaximumRenewalDate,
		selectedCarrierId,
		selectedPolicyLineOfBusinessIds,
		selectedContact,
		selectedOwner,
		debouncedSearchFieldValue,
	]);

	const policyFilterQuery = useInsurancePolicyFilter({
		enabled: true,
		filterRequest: {
			criteria: policyFilterCriteria,
		},
	});

	const policySummaryQuery = useInsurancePolicySummaryQuery({
		enabled: true,
		filterRequest: {
			criteria: policyFilterCriteria,
		},
	});

	const refreshPolicies = () => {
		setPolicyToEdit(null);
		invalidateInsurancePolicyFilter({
			filterRequest: {
				criteria: policyFilterCriteria,
			},
		});
		invalidateInsurancePolicySummary({
			filterRequest: {
				criteria: policyFilterCriteria,
			},
		});
	};

	const policyContactsMutation = useInsurancePolicyContactsMutation();
	const exportPoliciesMutation = useExportInsurancePoliciesMutation();
	const [actionButtonsDisabled, setActionButtonsDisabled] = React.useState(false);

	const getMatchingContacts = async () => {
		setActionButtonsDisabled(true);
		let results: Api.IProjectedContact[] = [];
		if (selectionState === Api.ESelectionState.Some) {
			results = policies.filter(policy => selectedPolicyRows.indexOf(policy.id) > -1).map(policy => policy.contact);
		} else {
			try {
				const apiResults = await policyContactsMutation.mutateAsync({
					filterRequest: {
						criteria: policyFilterCriteria,
					},
				});

				const excludedContactIdsMap = new Set(excludedContacts.map(contact => contact.id));
				results = apiResults.filter(contact => !excludedContactIdsMap.has(contact.id));
			} catch (error) {
				logApiError('ResolveContacts-Error', error);
			}
		}
		setActionButtonsDisabled(false);
		return results;
	};

	const policies = React.useMemo(() => {
		return policyFilterQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [policyFilterQuery.data]);

	const allToggleState =
		selectedPolicyRows.length === policies.length ? 'all' : selectedPolicyRows.length > 0 ? 'some' : 'none';

	const onTogglePolicyRow = (policy: Api.IInsurancePolicy) => {
		const currentIndex = selectedPolicyRows.indexOf(policy.id);
		const nextSelectedRows = [...selectedPolicyRows];

		if (selectionState === Api.ESelectionState.All) {
			const nextExcludedContacts = [...excludedContacts];
			if (currentIndex > -1) {
				nextExcludedContacts.push(policy.contact);
			} else {
				nextExcludedContacts.splice(currentIndex, 1);
			}
			setExcludedContacts(nextExcludedContacts);
		} else {
			const isLastSelectedContact = currentIndex > -1 && selectedPolicyRows.length === 1;
			if (isLastSelectedContact) {
				setSelectionState(Api.ESelectionState.None);
			} else if (nextSelectedRows) {
				setSelectionState(Api.ESelectionState.Some);
			}
		}

		if (currentIndex > -1) {
			nextSelectedRows.splice(currentIndex, 1);
		} else {
			nextSelectedRows.push(policy.id);
		}
		setSelectedPolicyRows(nextSelectedRows);
	};

	const onToggleSelectAll = () => {
		if (selectedPolicyRows.length > 0) {
			setSelectionState(Api.ESelectionState.None);
			setSelectedPolicyRows([]);
		} else {
			setSelectionState(Api.ESelectionState.All);
			setSelectedPolicyRows(policies.map(policy => policy.id));
		}
	};

	const clearSearch = () => {
		const donorNameOption = PolicyPropertyOptions[0];
		setSelectedSearchProperty(donorNameOption);
		setSearchFieldValue('');
		setSelectedCarrierId(null);
		setMinimumPremium('');
		setMaximumPremium('');
		setSelectedPolicyLineOfBusinessIds([]);
		setSelectedMinimumRenewalDate(null);
		setSelectedMaximumRenewalDate(null);
		setSelectedContact(null);
		setSelectedOwner(null);
	};

	const onSearchFieldInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchFieldValue(e.target.value);
	};

	const addPolicyClicked = () => {
		setPolicyToEdit({} as Api.IInsurancePolicy);
		setIsAddPopoverOpen(false);
		setIsEditOpen(true);
	};

	const editPolicy = (policy: Api.IInsurancePolicy) => {
		setPolicyToEdit(policy);
		setIsEditOpen(true);
	};

	const deletePolicy = (policy: Api.IInsurancePolicy) => {
		setPolicyToDelete(policy);
		setIsDeleteOpen(true);
	};

	const importClicked = () => {
		setIsAddPopoverOpen(false);

		const locationState: ILocationState<any, IContactsSelfImportRequest> = {
			model: {
				onFinish: e => {
					e.preventDefault();
					refreshPolicies();
				},
			},
		};

		fullscreenModal.history.push({
			pathname: `/people/contacts-self-import/upload`,
			state: locationState,
		});
	};

	const selectSearchProperty = (selectedOption: ISelectOption<PolicyPropertyOption>) => {
		setSearchFieldValue('');
		setSelectedCarrierId(null);
		setSelectedOwner(null);
		setSelectedSearchProperty(selectedOption);
	};

	const onCarrierSearchClear = () => {
		setSelectedCarrierId(null);
	};

	const viewDetails = (contact: Api.IContact) => {
		setContactToView(contact);
		setIsDetailsOpen(true);
	};

	const onDeleteConfirmed = () => {
		setIsDeleteOpen(false);
		refreshPolicies();
	};

	const onExport = () => {
		exportPoliciesMutation.mutate({
			filterRequest: {
				criteria: policyFilterCriteria,
			},
		});
	};

	const onContactCleared = () => {
		setSelectedContact(null);
	};
	const onContactSelected = ({ selection }: { selection: Api.IEntity }) => {
		setSelectedContact(selection);
	};

	const onOwnerCleared = () => {
		setSelectedOwner(null);
	};
	const onOwnerSelected = ({ selection }: { selection: Api.IEntity }) => {
		console.log(selection);
		setSelectedOwner(selection);
	};

	const { isLoading } = policyFilterQuery;

	const noResultsFound = !isLoading && policyFilterQuery.isFetched && policies.length === 0;

	const selectCarrier = (carrier: Api.IInsurancePolicyCarrier) => {
		setSelectedCarrierId(carrier.id);
	};

	return (
		<div className={css(styleSheet.container)}>
			<MultiContainerHeader
				appBarHeader={
					<NavigationBreadcrumbsBar
						currentLocationName={locationName}
						pathComponentNameProvider={() => 'Data Boards'}
					/>
				}
				fullscreenHeader={locationName}
			/>

			<header className={css(dbStyleSheet.header)}>
				<div className={css(dbStyleSheet.propertySearch)}>
					<Select
						styles={[dbStyleSheet.searchPropertySelector]}
						onOptionClick={selectedOption => selectSearchProperty(selectedOption)}
						options={PolicyPropertyOptions}
						selectedOption={selectedSearchProperty}
					/>

					{selectedSearchProperty.text === PolicyPropertyOption.PolicyHolder ? (
						<SimpleAutoCompleteSearchField
							onClear={onContactCleared}
							// @ts-ignore
							onItemSelected={onContactSelected}
							resultsLimit={5}
							pageSize={5}
							placeholder='Search'
							type={Api.ResourceAutoCompleteViewModelType.Contact}
							initialSearchQuery=''
							anchorStyles={[dbStyleSheet.comboBoxRightFullHeight]}
						/>
					) : null}

					{selectedSearchProperty.text === PolicyPropertyOption.PolicyNumber ? (
						<TextInput
							inputId='policy-number-search-input'
							type='text'
							className={css(dbStyleSheet.comboBoxRightAutocomplete)}
							inputClassName={css(dbStyleSheet.wideInput)}
							onChange={onSearchFieldInputChanged}
							value={searchFieldValue}
							leftAccessory={<SearchIcon className={css(dbStyleSheet.searchIcon)} />}
							placeholder='Search'
						/>
					) : null}

					{selectedSearchProperty.text === PolicyPropertyOption.Carrier ? (
						<PolicyCarrierSearch
							onSelectCarrier={selectCarrier}
							initialSearchFragment=''
							onCreateCarrier={selectCarrier}
							onClear={onCarrierSearchClear}
							className={css(dbStyleSheet.comboBoxRight, dbStyleSheet.wideInput)}
						/>
					) : null}

					{selectedSearchProperty.text === PolicyPropertyOption.PolicyOwner ? (
						<SimpleAutoCompleteSearchField
							onClear={onOwnerCleared}
							// @ts-ignore
							onItemSelected={onOwnerSelected}
							resultsLimit={5}
							pageSize={5}
							placeholder='Search'
							type={Api.ResourceAutoCompleteViewModelType.User}
							initialSearchQuery=''
							anchorStyles={[dbStyleSheet.comboBoxRightFullHeight]}
						/>
					) : null}
				</div>

				<div className={css(dbStyleSheet.mediumInput)}>
					<PolicyLineOfBusinessFilterDropdown
						selectedPolicyLineOfBusinessIds={selectedPolicyLineOfBusinessIds}
						setSelectedPolicyLineOfBusinessIds={setSelectedPolicyLineOfBusinessIds}
					/>
				</div>

				<div className={css(dbStyleSheet.mediumInput)}>
					<RenewalDateFilterDropdown
						selectedMinimumDate={selectedMinimumRenewalDate}
						setSelectedMinimumDate={setSelectedMinimumRenewalDate}
						selectedMaximumDate={selectedMaximumRenewalDate}
						setSelectedMaximumDate={setSelectedMaximumRenewalDate}
					/>
				</div>

				<div className={css(dbStyleSheet.mediumInput)}>
					<PremiumFilterDropdown
						key={`premiumFilterDropdown-${minimumPremium}-${maximumPremium}`}
						initialMinimumPremium={minimumPremium}
						initialMaximumPremium={maximumPremium}
						onChange={value => {
							setMinimumPremium(value.minimumPremium);
							setMaximumPremium(value.maximumPremium);
						}}
					/>
				</div>

				{hasSearchValues ? (
					<div className={css(dbStyleSheet.clearSearch)}>
						<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={clearSearch}>
							<span>Clear Search</span>
						</button>
					</div>
				) : null}
			</header>

			<header className={css(dbStyleSheet.header, dbStyleSheet.indented)}>
				<div className={css(dbStyleSheet.leftHeader)}>
					<UsersActionBar
						getMatchingContacts={getMatchingContacts}
						onExport={onExport}
						selectionState={selectionState}
						actionButtonsDisabled={actionButtonsDisabled}
					/>
				</div>

				<div className={css(dbStyleSheet.rightHeader)}>
					<TinyPopover
						anchor={
							<div className={css(dbStyleSheet.addButtonContainer)}>
								<button className={css(baseStyleSheet.ctaButton, dbStyleSheet.addButton)} onClick={addPolicyClicked}>
									<span>Add Policy</span>
								</button>
								<button className={css(dbStyleSheet.dropdownCaret)} onClick={() => setIsAddPopoverOpen(true)}>
									<DropDownIcon fillColor={white} />
								</button>
							</div>
						}
						isOpen={isAddPopoverOpen}
						dismissOnOutsideAction={true}
						align='center'
						placement={['bottom']}
						onRequestClose={() => setIsAddPopoverOpen(false)}
					>
						<button className={css(baseStyleSheet.ctaButtonReverse, dbStyleSheet.importButton)} onClick={importClicked}>
							Import from CSV
						</button>
					</TinyPopover>
				</div>
			</header>

			<div className={css(dbStyleSheet.tableContainer)}>
				<table className={css(dataGridStyles.table, dbStyleSheet.tableOverrides)}>
					<thead className={css(dataGridStyles.tableHeader)}>
						<tr className={css(dataGridStyles.tableRow, dataGridStyles.tableHeaderRow)}>
							<th className={css(dataGridStyles.tableHeaderCell)}>
								<Checkbox
									checked={allToggleState === 'all'}
									id='select-all-policies'
									onChange={onToggleSelectAll}
									partial={allToggleState === 'some'}
								/>
							</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Name</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Line of Business</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Renewal Date</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Premium</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Carrier</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>Policy #</th>
							<th className={css(dataGridStyles.tableHeaderCell)}>&nbsp;</th>
						</tr>
					</thead>

					<tbody>
						{!isLoading
							? policies.map(policy => {
									return (
										<tr key={`policy-${policy.id}`} className={css(dataGridStyles.tableRow)}>
											<td className={css(dataGridStyles.tableDataCell)}>
												<Checkbox
													type='large'
													checked={selectedPolicyRows.indexOf(policy.id) > -1}
													id={policy.id}
													onChange={() => onTogglePolicyRow(policy)}
												/>
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												<button onClick={() => viewDetails(policy.contact)}>
													<h6 className={css(dbStyleSheet.name, baseStyleSheet.truncateText, baseStyleSheet.fontBold)}>
														{policy.contact.handle}
													</h6>
												</button>
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{policy.lineOfBusiness ? (
													<Badge
														text={policy.lineOfBusiness.name}
														foregroundColor={policy.lineOfBusiness.foregroundColor}
													/>
												) : null}
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												{moment(policy.renewalDate).format('MM/DD/YYYY')}
											</td>
											<td className={css(dataGridStyles.tableDataCell, dbStyleSheet.amountText)}>
												${numberToCurrencyStringValue(policy.premium)}
											</td>
											<td className={css(dataGridStyles.tableDataCell)}>{policy.carrier?.name}</td>
											<td className={css(dataGridStyles.tableDataCell)}>{policy.policyNumber}</td>
											<td className={css(dataGridStyles.tableDataCell)}>
												<MoreMenu>
													<MoreMenuItem onClick={() => editPolicy(policy)}>{MenuItem.Edit}</MoreMenuItem>
													<MoreMenuItem onClick={() => deletePolicy(policy)}>{MenuItem.Delete}</MoreMenuItem>
												</MoreMenu>
											</td>
										</tr>
									);
								})
							: null}

						{isLoading ? (
							<tr className={css(styleSheet.loadingRow)}>
								<td colSpan={8}>
									<LoadingSpinner type='large' />
								</td>
							</tr>
						) : null}
					</tbody>
				</table>

				<Waypoint bottomOffset='-200px' onEnter={() => policyFilterQuery.fetchNextPage()} />

				{noResultsFound ? (
					<div className={css(dbStyleSheet.noResults)}>
						<EmptySearchIcon />

						<span>We did not find anyone matching your search criteria.</span>
						<span>Please clear your filters and try again.</span>

						<button className={css(baseStyleSheet.ctaButton)} onClick={clearSearch}>
							<span>Clear Search</span>
						</button>
					</div>
				) : null}

				<div className={css(dbStyleSheet.totalRow)}>
					<div className={css(dbStyleSheet.statHeader)}>Policy Stats</div>

					<div className={css(dbStyleSheet.stat)}>
						<h6 className={css(dbStyleSheet.statTitle)}># Policies</h6>
						<span className={css(dbStyleSheet.statValue)}>{policySummaryQuery.data?.totalPolicies || 0}</span>
					</div>

					<div className={css(dbStyleSheet.stat)}>
						<h6 className={css(dbStyleSheet.statTitle)}>Total</h6>
						<span className={css(dbStyleSheet.totalValue)}>
							${numberToCurrencyStringValue(policySummaryQuery.data?.totalAmount || 0)}
						</span>
					</div>

					<div>&nbsp;</div>
				</div>
			</div>

			{policyToEdit ? (
				<EditPolicyModal
					initialPolicy={policyToEdit}
					isOpen={isEditOpen}
					setIsOpen={setIsEditOpen}
					onSave={refreshPolicies}
					onCancel={refreshPolicies}
				/>
			) : null}

			{contactToView ? (
				<PolicyHolderDetailsFlyout isOpen={isDetailsOpen} setIsOpen={setIsDetailsOpen} partialContact={contactToView} />
			) : null}

			{policyToDelete ? (
				<DeleteModal
					type={DeleteType.Policy}
					item={policyToDelete}
					isOpen={isDeleteOpen}
					setIsOpen={setIsDeleteOpen}
					onConfirm={onDeleteConfirmed}
				/>
			) : null}

			<ExportConfirmationModalV2
				// @ts-ignore
				mutationResult={exportPoliciesMutation}
				modalProps={{
					isOpen: !exportPoliciesMutation.isIdle,
					onRequestClose: () => exportPoliciesMutation.reset(),
				}}
				subTitle={
					<>
						<p className={css(dbStyleSheet.noMargin)}>
							The fields we&apos;ll export include: Policy Name, Contact Id, Lifetime Policies, Most Recent Policy
							Amount, Most Recent Policy Date, Most Recent Policy Carrier Name
						</p>
					</>
				}
			/>

			<FabContext appearance={{ hidden: true }} />
		</div>
	);
}
