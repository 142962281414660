import { StyleSheet } from 'aphrodite';
import { black, brandPrimary, brandSecondary, grayIconFill, warningDark, white } from '../../../../web/styles/colors';
import { BreakPoints, CssSelectors, baseStyleSheet, Layouts } from '../../../../web/styles/styles';
import BackgroundImageUrl from '../../ai/AIContentGenerationWizard/aiAssistantThinkingBackground.svg';

const contentPadding = 20;
const headerHeight = 35;
const mobileBreakpoint = 720;

const thinkingBackgroundColorAnimation = {
	'0%': {
		backgroundColor: '#C7D4DB',
		transform: 'translate3d(0, 0%, 0)',
	},
	'100%': {},
	'15%': {
		backgroundColor: '#F9F9F9',
		transform: 'translate3d(0, -30%, 0)',
	},
	'35%': {
		backgroundColor: '#C7D4DB',
		transform: 'translate3d(0, 0%, 0)',
	},
	'80%': {},
};

const thinkingElementAnimation = (elementIndex: number) => {
	const baseDuration = 225;
	const animationDuration = 6 * baseDuration;
	const animationDelay = (elementIndex - 1) * (2 * baseDuration);
	return {
		animationDelay: `${animationDelay}ms`,
		animationDuration: `${animationDuration}ms`,
		animationIterationCount: 'infinite',
		animationName: [thinkingBackgroundColorAnimation],
		animationTimingFunction: 'ease-in',
	};
};

export const styleSheet = StyleSheet.create({
	author: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: 8,
	},
	avatar: {
		height: 48,
		width: 48,
	},
	body: {
		'@media only screen and (min-width: 800px)': {
			minWidth: 800,
		},
		display: 'flex',
		flexDirection: 'column',
		height: 470,
		maxHeight: 470,
		minHeight: 470,
		maxWidth: '100vw',
	},
	buttonContainer: {
		display: 'flex',
		marginTop: 36,
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	closeButton: {
		height: 18,
	},
	container: {},
	errorContainer: {
		color: warningDark,
	},
	header: {
		alignItems: 'center',
		background: 'linear-gradient(158deg, #009FFF, #0066FF)',
		borderTopLeftRadius: 4,
		borderTopRightRadius: 4,
		color: white,
		display: 'flex',
		height: headerHeight,
		justifyContent: 'space-between',
		padding: `0px ${contentPadding}px`,
	},
	locationIcon: {
		left: 0,
		position: 'absolute',
		top: '50%',
		transform: 'translate(0, -50%)',
	},
	location: {
		color: brandPrimary,
		fontSize: 10,
		marginTop: 8,
		paddingLeft: 24,
		position: 'relative',
		[BreakPoints.forMaxWidth(768)]: {
			display: 'inline-block',
			width: 'auto',
		},
		[BreakPoints.forMaxWidth(mobileBreakpoint)]: {
			marginTop: 5,
		},
	},
	modal: {
		...CssSelectors.allDescendants(
			{
				borderRadius: 6,
				boxSizing: 'content-box',
				padding: 0,
			},
			'.modal-overlay-content'
		),
	},
	aiResponseContainer: {
		flex: '1',
	},
	responseContainer: {
		flex: '1',
	},
	nonAiResponse: {
		padding: 20,
	},
	responseRow: {
		borderTop: `1px solid ${grayIconFill}`,
		display: 'flex',
		flexDirection: 'row',
	},
	responseTitle: {
		marginTop: 0,
	},
	pill: {
		alignItems: 'center',
		background: brandSecondary,
		borderRadius: 60,
		boxSizing: 'border-box',
		color: white,
		display: 'flex',
		fontSize: 12,
		height: 'auto',
		margin: 0,
		marginTop: 8,
		padding: '14px 32px',
		position: 'relative',
	},
	rating: {
		...CssSelectors.allChildren({
			display: 'block',
		}),
	},
	ratingStar: {
		height: 16,
		width: 16,
	},
	reviewerColumn: {
		backgroundImage:
			'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(157, 215, 237, 0.2) 30%, rgba(81, 186, 229, 0.2) 68%, rgba(63, 178, 227, 0.2) 100%)',
		borderRight: `1px solid ${grayIconFill}`,
		display: 'flex',
		flexDirection: 'column',
		padding: 16,
		width: '30%',
	},
	reviewer: {
		display: 'flex',
		flexDirection: 'row',
	},
	reviewColumn: {
		overflowY: 'scroll',
		padding: 16,
		width: '70%',
	},
	reviewerName: {
		color: black,
		fontSize: '1rem',
		...CssSelectors.allDescendants(
			{
				...baseStyleSheet.fontBold,
			},
			'strong'
		),
	},
	reviewRow: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		height: 108,
	},
	reviewText: {
		fontSize: 12,
		marginTop: 8,
		marginBottom: 8,
		textAlign: 'left',
	},
	thinking: {
		alignItems: 'center',
		boxSizing: 'border-box',
		display: 'flex',
		position: 'relative',
		...CssSelectors.allChildren({
			':not(:first-child)': {
				marginLeft: 5,
			},
			':nth-child(1)': thinkingElementAnimation(1),
			':nth-child(2)': thinkingElementAnimation(2),
			':nth-child(3)': thinkingElementAnimation(3),
			backgroundColor: '#C7D4DB',
			borderRadius: '50%',
			height: 12,
			width: 12,
		}),
	},
	thinkingLionImage: {
		paddingTop: 56,
	},
	thinkingModalMessages: {
		...Layouts.verticalStack(15),
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	thinkingOverlay: {
		background: 'none',
		backgroundImage: `url(${BackgroundImageUrl})`,
		backgroundSize: 'cover',
		borderRadius: 6,
		width: 800,
		height: 510,
		position: 'relative',
		textAlign: 'center',
	},
	timeAgo: {
		color: black,
		fontSize: 12,
		fontWeight: 600,
		opacity: 0.5,
	},
});
