import * as Api from '@ViewModels';
import { GoogleBusinessReplyModal } from '@WebComponents/googleBusinessProfiles/ReplyModal';
import { css } from 'aphrodite';
import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getRatingIntFromText } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useGetGoogleBusinessMetadata, useGetResourceSelector, useInfiniteReviews } from '../../../../queries';
import { SuccessCheckIcon } from '../../../../web/components/svgs/icons/SuccessCheckIcon';
import googleLogo from '../../../assets/google_logo.png';
import { nameCircles, success, successTint } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { StarRating } from '../../surveys/StarRating';
import { GoogleBusinessProfileConnectedIntegrationGraphic } from '../../svgs/graphics/GoogleBusinessProfileConnectedIntegrationGraphic';
import { GoogleBusinessProfileNoReviews } from '../../svgs/graphics/GoogleBusinessProfileNoReviews';
import { SliderArrowLeft } from '../../svgs/icons/SliderArrowLeft';
import { SliderArrowRight } from '../../svgs/icons/SliderArrowRight';
import { DashboardCard, DashboardCardStat, DashboardCardTag } from '../DashboardCard';
import { styleSheet } from './styles';

export const DashboardGoogleBusinessProfileCard = () => {
	const [visibleReviewIndex, setVisibleReviewIndex] = useState(0);
	const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);

	const userSession = useUserSession();

	const { data: metadata, status: metadataStatus } = useGetGoogleBusinessMetadata({});

	const isConnected = metadata?.connectionState === Api.GoogleBusinessConnectionState.Connected;

	const {
		data: reviewData,
		status: reviewStatus,
		fetchNextPage,
	} = useInfiniteReviews({
		enabled: isConnected,
		refetchOnWindowFocus: false,
	});

	const { data, status } = useGetResourceSelector({
		enabled: metadataStatus === 'success' && isConnected,
		resourceName: 'GoogleBusinessReviews',
	});

	const isLoaded = status === 'success';

	if (!isLoaded || (isLoaded && !isConnected)) {
		return null;
	}

	const recentReviews = reviewData?.pages
		? reviewData.pages
				.map(page => {
					// @ts-ignore
					return page.values;
				})
				.flat()
		: [];

	const activeReview = recentReviews.length ? recentReviews[visibleReviewIndex] : null;

	const getDaysAgoText = (value: any) => {
		return moment(value).fromNow();
	};

	const postResponse = () => {
		setIsReplyModalOpen(true);
	};

	const handleNextClick = () => {
		const isOneAwayFromNeedingMoreData = visibleReviewIndex + 2 === recentReviews.length;
		// @ts-ignore
		const isNotFullyLoaded = recentReviews.length !== metadata.totalReviews;
		const isNotAlreadyLoading = reviewStatus !== 'loading';

		if (isOneAwayFromNeedingMoreData && isNotFullyLoaded && isNotAlreadyLoading) {
			fetchNextPage();
		}

		setVisibleReviewIndex(visibleReviewIndex + 1);
	};

	const handleBackClick = () => {
		setVisibleReviewIndex(visibleReviewIndex - 1);
	};

	const rating = getRatingIntFromText(activeReview?.rating);
	const totalStars = 5;
	const isLatestReview = visibleReviewIndex === 0 && recentReviews.length;
	// @ts-ignore
	const showStats = data[0]?.percentReviewsIncreased > 0;
	const hasReviews = recentReviews.length > 0;

	if (!hasReviews) {
		return (
			<DashboardCard styleDeclaration={styleSheet.root}>
				<header className={css(styleSheet.cardHeader)}>
					<div className={css(styleSheet.headerColumns)}>
						<figure className={css(styleSheet.logoWrapper)}>
							<img className={css(styleSheet.logo)} src={googleLogo} />
							<figcaption>Business Profile</figcaption>
						</figure>
					</div>
				</header>

				<section className={css(styleSheet.bodyFull)}>
					<article>
						<h2>Let&apos;s get started</h2>

						<a href='#/surveys' className={css(baseStyleSheet.ctaButtonReverse, styleSheet.getMoreReviews)}>
							Get more reviews
						</a>
					</article>

					<figure className={css(styleSheet.noReviewsImage)}>
						<GoogleBusinessProfileNoReviews />
					</figure>
				</section>
			</DashboardCard>
		);
	}

	return (
		<DashboardCard styleDeclaration={styleSheet.root}>
			<header className={css(styleSheet.cardHeader)}>
				<div className={css(styleSheet.headerColumns)}>
					<figure className={css(styleSheet.logoWrapper)}>
						<img className={css(styleSheet.logo)} src={googleLogo} />
						<figcaption> Business Profile</figcaption>
					</figure>

					<div className={css(styleSheet.ratingsTitle)}>
						{/* @ts-ignore */}
						<span>{metadata.averageRating}</span>
						<StarRating
							fillColor='#F3CE0D'
							total={totalStars}
							// @ts-ignore
							value={metadata.averageRating}
							starStyles={[styleSheet.ratingStar]}
						/>
						{/* @ts-ignore */}
						<span>({metadata.totalReviews})</span>
					</div>
				</div>
			</header>

			<article className={css(styleSheet.bodyColumns)}>
				{!showStats ? (
					<figure className={css(styleSheet.bodyLeft)}>
						<GoogleBusinessProfileConnectedIntegrationGraphic />

						<figcaption>Let&apos;s get started</figcaption>

						<Link to='/surveys'>Get more reviews</Link>
					</figure>
				) : null}

				{showStats ? (
					<div className={css(styleSheet.bodyLeft)}>
						<DashboardCardTag styleDeclaration={[styleSheet.redBadge, styleSheet.wideBadge]}>
							Since Using Levitate
						</DashboardCardTag>

						<div className={css(styleSheet.percentIncreaseContainer)}>
							<DashboardCardStat styleDeclaration={styleSheet.percentIncreaseStat}>
								{/* @ts-ignore */}
								<span className={css(styleSheet.percentIncreaseTitle)}>+ {data[0].percentReviewsIncreased}%</span>
								<span className={css(styleSheet.percentIncreaseSubtitle)}>Increase in reviews</span>
							</DashboardCardStat>
						</div>

						<Link to='/surveys'>Get more reviews</Link>
					</div>
				) : null}

				<section className={css(styleSheet.bodyRight)}>
					<div className={css(styleSheet.goBackButton)}>
						<button onClick={handleBackClick} disabled={visibleReviewIndex === 0}>
							<SliderArrowLeft fillColor={nameCircles} />
						</button>
					</div>

					{activeReview ? (
						<div>
							<DashboardCardTag styleDeclaration={isLatestReview ? styleSheet.redBadge : styleSheet.blueBadge}>
								{isLatestReview ? 'Latest Review' : 'Google Reviews'}
							</DashboardCardTag>

							<span className={css(styleSheet.author)}>{activeReview.author}</span>

							<div className={css(styleSheet.rating)}>
								<StarRating
									fillColor='#F3CE0D'
									total={totalStars}
									value={rating}
									starStyles={[styleSheet.ratingStar]}
								/>{' '}
								<span className={css(styleSheet.daysAgo)}>{getDaysAgoText(activeReview.updateTime)}</span>
							</div>

							<p className={css(styleSheet.reviewText)}>{activeReview.text}</p>

							{activeReview.reply ? (
								<p className={css(styleSheet.respondedText)}>
									<SuccessCheckIcon fillColor={success} ringColor={successTint} />
									<em>Response posted {getDaysAgoText(activeReview.replyTime)}</em>
								</p>
							) : null}

							{!activeReview.reply && userSession.isAdmin ? (
								<div className={css(styleSheet.buttonContainer)}>
									<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={postResponse}>
										Post a response
									</button>
								</div>
							) : null}
						</div>
					) : null}

					<div className={css(styleSheet.goNextButton)}>
						{/* @ts-ignore */}
						<button onClick={handleNextClick} disabled={visibleReviewIndex + 1 === metadata.totalReviews}>
							<SliderArrowRight fillColor={nameCircles} />
						</button>
					</div>
				</section>
			</article>

			{activeReview ? (
				<GoogleBusinessReplyModal
					isOpen={isReplyModalOpen}
					reviewId={activeReview.id}
					setIsOpen={setIsReplyModalOpen}
				/>
			) : null}
		</DashboardCard>
	);
};
