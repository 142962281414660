import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IPushNotificationsComponentProps,
	IUserSessionComponentProps,
	PushNotificationsViewModelKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import {
	INotificationMethods,
	IOperationResultNoValue,
	IUserPreferences,
	NotifyOf,
	UserViewModel,
	asApiError,
} from '@ViewModels';
import { SettingsGroup } from '@WebComponents/settings/SettingsGroup';
import { SettingsGroupIcon } from '@WebComponents/settings/SettingsGroupIcon';
import { StyleDeclarationValue, css } from 'aphrodite';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { BrowserPushNotificationsViewModel } from '../../../../../viewmodels/PushNotifications';
import { error as errorColor } from '../../../../styles/colors';
import { baseStyleSheet } from '../../../../styles/styles';
import { Checkbox } from '../../../Checkbox';
import { HelpTooltip } from '../../../HelpTooltip';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IUserSessionComponentProps,
		IPushNotificationsComponentProps,
		IErrorMessageComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	userViewModel: UserViewModel;
}

interface IState {
	notifyOf: { [key in NotifyOf]: INotificationMethods };
	toggleEmailPushNotifications: boolean;
	toggleTextingPushNotifications: boolean;
	saving: boolean;
}

class _NotificationsSettings extends React.Component<IProps, IState> {
	private get pushNotificationsEnabled() {
		if (!BrowserPushNotificationsViewModel.isSupported) {
			return false;
		}

		const permissionDenied = !!window.Notification && window.Notification.permission === 'denied';
		return !permissionDenied;
	}

	private pushNotificationsChecked(type: NotifyOf) {
		if (!this.pushNotificationsEnabled) {
			return false;
		}

		const { pushNotifications, userViewModel } = this.props;
		const { toggleEmailPushNotifications, toggleTextingPushNotifications } = this.state;
		const enabled =
			!!userViewModel.preferences && !!userViewModel.preferences.notifyOf && !!userViewModel.preferences.notifyOf[type]
				? userViewModel.preferences.notifyOf[type].pushNotifications
				: true;

		// @ts-ignore
		const checked = enabled && pushNotifications.isActive;

		if (type === NotifyOf.TextsReceived) {
			return toggleTextingPushNotifications ? !checked : checked;
		} else if (type === NotifyOf.ReadEmails) {
			return toggleEmailPushNotifications ? !checked : checked;
		}
	}

	constructor(props: IProps) {
		super(props);
		this.state = {
			// @ts-ignore
			notifyOf: this.getUserSessionNotifyOfSettings(),
			saving: false,
			toggleEmailPushNotifications: false,
			toggleTextingPushNotifications: false,
		};
	}

	public render() {
		const { className, styles } = this.props;
		const { saving } = this.state;
		return (
			<SettingsGroup
				className={`${css(styleSheet.container, ...(styles || []))} notifications-settings ${className || ''}`}
				description='Manage how you want to be notified when people read your email'
				name='Notifications'
				icon={<SettingsGroupIcon type='Notifications' />}
			>
				<div className={css(styleSheet.title)}>Track with Levitate - notify when a recipient has read your email</div>
				<div className={css(styleSheet.body)}>{this.renderEmailCheckBoxes()}</div>
				<div className={css(styleSheet.title)}>Text with Levitate - notify when a new text message is received</div>
				<div className={css(styleSheet.body)}>{this.renderTextingCheckBoxes()}</div>
				<div className={css(styleSheet.footer)}>
					<button className={css(baseStyleSheet.ctaButton)} onClick={this.onSaveButtonClicked} disabled={saving}>
						<span>Save</span>
					</button>
					<button
						className={css(baseStyleSheet.ctaButtonReverse)}
						onClick={this.onCancelButtonClicked}
						disabled={saving}
					>
						<span>Cancel</span>
					</button>
				</div>
			</SettingsGroup>
		);
	}

	private renderEmailCheckBoxes() {
		const { notifyOf, saving } = this.state;

		if (saving || !notifyOf) {
			return <LoadingSpinner />;
		}

		return (
			<div className={css(styleSheet.checkboxes)}>
				<Checkbox
					checked={!!notifyOf.ReadEmails?.email}
					className={css(styleSheet.checkbox, styleSheet.emailCheckbox)}
					id='notifications-settings-option-email'
					key='email'
					onChange={this.onCheckboxChanged(NotifyOf.ReadEmails, 'email')}
				>
					<span>Notify me via email</span>
				</Checkbox>
				{this.renderBrowserPushOption(NotifyOf.ReadEmails)}
			</div>
		);
	}

	private renderTextingCheckBoxes() {
		const { notifyOf, saving } = this.state;

		if (saving) {
			return <LoadingSpinner />;
		}

		return (
			<div className={css(styleSheet.checkboxes)}>
				<Checkbox
					checked={!!notifyOf.TextsReceived?.email}
					className={css(styleSheet.checkbox, styleSheet.emailCheckbox)}
					id='notifications-settings-option-texting'
					key='email'
					onChange={this.onCheckboxChanged(NotifyOf.TextsReceived, 'email')}
				>
					<span>Notify me via email</span>
				</Checkbox>
				{this.renderBrowserPushOption(NotifyOf.TextsReceived)}
			</div>
		);
	}

	private renderBrowserPushOption(type: NotifyOf) {
		if (!BrowserPushNotificationsViewModel.isSupported) {
			return null;
		}

		const permissionDenied = !!window.Notification && window.Notification.permission === 'denied';
		const enabled = !permissionDenied && this.pushNotificationsEnabled;
		const checked = this.pushNotificationsChecked(type);

		return (
			<div className={css(styleSheet.pushCheckboxContainer)}>
				<Checkbox
					checked={checked}
					className={css(styleSheet.checkbox)}
					disabled={!enabled}
					id={`notifications-settings-option-pushNotifications-${type}`}
					key={`pushNotifications-${type}`}
					onChange={this.onTogglePushNotifications(type)}
				>
					<span>Notify me via browser notifications</span>
				</Checkbox>
				{!!permissionDenied && (
					<HelpTooltip>
						<span className={css(styleSheet.pushCheckboxDeniedHelp)}>
							<span style={{ color: errorColor }}>Permission denied.</span>
							&nbsp;
							<span>You previously denied Levitate permission to send notifications to this browser.</span>
							<br />
							<span>To re-enable this option, grant Levitate permission from within your browser&apos;s settings.</span>
						</span>
					</HelpTooltip>
				)}
			</div>
		);
	}

	private onCancelButtonClicked = () => {
		this.setState({
			// @ts-ignore
			notifyOf: this.getUserSessionNotifyOfSettings(),
			toggleEmailPushNotifications: false,
			toggleTextingPushNotifications: false,
		});
	};

	private onTogglePushNotifications = (type: NotifyOf) => () => {
		const { toggleEmailPushNotifications, toggleTextingPushNotifications } = this.state;
		if (type === NotifyOf.ReadEmails) {
			this.setState({
				toggleEmailPushNotifications: !toggleEmailPushNotifications,
			});
		} else if (type === NotifyOf.TextsReceived) {
			this.setState({
				toggleTextingPushNotifications: !toggleTextingPushNotifications,
			});
		}
	};

	private onCheckboxChanged =
		(notifyOfType: NotifyOf, type: keyof INotificationMethods) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const { notifyOf } = this.state;
			const checked = !!e.target.checked;

			const nextNotifyOf = { ...notifyOf };
			nextNotifyOf[notifyOfType][type] = checked;
			this.setState({ notifyOf: nextNotifyOf });
		};

	private onSaveButtonClicked = () => {
		this.setState(
			{
				saving: true,
			},
			async () => {
				await this.saveSettings();
			}
		);
	};

	private updateUserPrefs = async (preferences: IUserPreferences) => {
		const { notifyOf } = this.state;
		const { userViewModel, logApiError, logEvent, errorMessages } = this.props;
		const promise = userViewModel.updateUserPreferences(preferences);
		if (!promise) {
			return;
		}

		// @ts-ignore
		logEvent('UpdateUserPreferences', { notifyOf });
		await promise.catch((error: IOperationResultNoValue) => {
			// @ts-ignore
			logApiError('UpdateUserPreferences-Error', error);
			// @ts-ignore
			errorMessages.pushApiError(error);
			this.setState({
				// @ts-ignore
				notifyOf: this.getUserSessionNotifyOfSettings(),
			});
		});
		this.setState({
			saving: false,
			toggleEmailPushNotifications: false,
			toggleTextingPushNotifications: false,
		});
	};

	private saveSettings = async () => {
		const { notifyOf } = this.state;
		const { pushNotifications, userViewModel, logApiError, logEvent, errorMessages } = this.props;
		const userPrefs = { ...userViewModel.preferences };
		const emailPushNotificationsChecked = this.pushNotificationsChecked(NotifyOf.ReadEmails);
		const textingPushNotificationsChecked = this.pushNotificationsChecked(NotifyOf.TextsReceived);

		userPrefs.notifyOf = {
			...notifyOf,
		};

		if (!this.pushNotificationsEnabled) {
			await this.updateUserPrefs(userPrefs);
			return;
		}

		userPrefs.notifyOf[NotifyOf.ReadEmails].pushNotifications = emailPushNotificationsChecked;
		userPrefs.notifyOf[NotifyOf.TextsReceived].pushNotifications = textingPushNotificationsChecked;

		// @ts-ignore
		const promise = pushNotifications.toggleNotifications(
			// @ts-ignore
			emailPushNotificationsChecked || textingPushNotificationsChecked,
			userPrefs,
			userViewModel
		);
		if (promise) {
			await promise.catch(error => {
				const err = asApiError(error);
				// @ts-ignore
				logApiError('EnableBrowserPush-Error', err);
			});
		}

		// If no push notifications are on and the subscription is still active, then unsubscribe
		// @ts-ignore
		if (!!pushNotifications.isActive && !emailPushNotificationsChecked && !textingPushNotificationsChecked) {
			// @ts-ignore
			logEvent('DeactivatePushSubscription', {
				// @ts-ignore
				id: pushNotifications.subscription.id,
			});
			// @ts-ignore
			pushNotifications.deactivateSubscription()?.catch((error: IOperationResultNoValue) => {
				// @ts-ignore
				logApiError('DeactivatePushSubscription-Error', error);
				// @ts-ignore
				errorMessages.pushApiError(error);
				this.setState({
					// @ts-ignore
					notifyOf: this.getUserSessionNotifyOfSettings(),
				});
			});
		}

		await this.updateUserPrefs(userPrefs);
	};

	// @ts-ignore
	private getUserSessionNotifyOfSettings = () => toJS(this.props.userViewModel.preferences.notifyOf);
}

const NotificationsSettingsAsObserver = observer(_NotificationsSettings);
const NotificationsSettingsWithContext = inject(
	UserSessionViewModelKey,
	PushNotificationsViewModelKey,
	ErrorMessagesViewModelKey
)(NotificationsSettingsAsObserver);
export const NotificationsSettings = withEventLogging(NotificationsSettingsWithContext, 'NotificationsSettings');
