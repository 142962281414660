import * as Api from '@ViewModels';
import { VmUtils } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { TransparentButton } from '../../../../../aida/components/TransparentButton';
import {
	getContactTitleAndCompany,
	getDisplayName,
	getNormalizedNoteVisibility,
	getPrincipalInitials,
	sortContactTags,
} from '../../../../../models/UiUtils';
import { useFullscreenModal } from '../../../../../models/hooks/appStateHooks';
import { Avatar2 } from '../../../../components/Avatar2';
import { Checkbox } from '../../../../components/Checkbox';
import { TinyPopover } from '../../../../components/TinyPopover';
import { VisibilityIndicatorIcon } from '../../../../components/VisibilityIndicatorIcon';
import { KeepInTouchAvatarWrapper } from '../../../../components/contacts/KeepInTouchAvatarWrapper';
import { Tag } from '../../../../components/tags/Tag';
import { baseStyleSheet } from '../../../../styles/styles';
import { styleSheet } from './styles';

interface IBaseProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IProps extends IBaseProps {
	contact: Api.IContact;
	tagsToHighlight?: string[];
	onToggleSelection: () => void;
	isSelected: boolean;
}

const PeopleRowInternal: React.FC<IProps> = ({
	className,
	styles,
	contact,
	isSelected,
	onToggleSelection,
	tagsToHighlight,
}) => {
	// @ts-ignore
	const { history } = useFullscreenModal();

	// local values
	const title = getContactTitleAndCompany(contact);
	const name = getDisplayName(contact);
	const sortedTags = sortContactTags({ tags: contact.tags ?? [], priorityTags: tagsToHighlight });
	const visibleTags = sortedTags.slice(0, 3);
	const extraTags = sortedTags.slice(3);
	const phone = contact.phoneNumbers?.[0]?.value;
	const email = contact.primaryEmail?.value;
	const visibleConnections: Api.IContactConnection[] = contact.connections ? getVisibleConnections(contact) : [];
	const contactConnections = contact.connections || [];
	const overflowConnectionCount = contactConnections.length - visibleConnections.length;
	const contactOwner = visibleConnections.find(x => x?.user?.id === contact.ownerId);
	const otherContacts = visibleConnections.filter(x => x?.user?.id !== contact.ownerId);
	const [isOpen, setIsOpen] = React.useState(false);
	const onClickContainer = (event: React.MouseEvent<HTMLDivElement>) => {
		if (event.isDefaultPrevented()) {
			return;
		}
		setIsOpen(false);
		if (event.ctrlKey || event.metaKey) {
			window.open(window.location.origin + `/#/people/${contact.id}`);
		} else {
			history.push(`/people/${contact.id}`);
		}
	};
	const avatar = (
		<Avatar2
			styleDeclaration={[styleSheet.avatar]}
			fallbackText={getPrincipalInitials(contact)}
			imgSrc={contact?.profilePic}
		/>
	);

	const contactOwnerConnectionTypes = contactOwner?.connectionTypes
		? contactOwner?.connectionTypes.sort((a, b) => a.localeCompare(b))
		: null;

	return (
		<div
			className={css(styleSheet.outerContainer, isSelected && styleSheet.containerSelected)}
			onClick={onClickContainer}
		>
			<div className={`${css(styleSheet.container, ...(styles || []))} ${className || ''}`}>
				<div
					className={`${css(styleSheet.selection, ...(styles || []))} ${className || ''}`}
					onClick={ev => {
						ev.stopPropagation();
						ev.preventDefault();
						onToggleSelection();
					}}
				>
					<Checkbox
						checked={isSelected}
						id={`people-row-checkbox-${contact.id}`}
						childrenPosition='left'
						backgroundFillColor='white'
						readOnly={true}
					/>
				</div>
				<div className={`${css(styleSheet.visibility, ...(styles || []))} ${className || ''}`}>
					<VisibilityIndicatorIcon
						title={getNormalizedNoteVisibility(contact.visibility ?? 'none')}
						visibility={contact.visibility}
					/>
				</div>
				<div className={`${css(styleSheet.avatar, ...(styles || []))} ${className || ''}`}>
					<TransparentButton onClick={VmUtils.Noop}>
						{contact.userHasKeepInTouch ? <KeepInTouchAvatarWrapper>{avatar}</KeepInTouchAvatarWrapper> : avatar}
					</TransparentButton>
				</div>
				<div className={`${css(styleSheet.name, ...(styles || []))} ${className || ''}`}>
					<div className={css(baseStyleSheet.truncateText)}>{name}</div>
					{title && <div className={css(styleSheet.title)}> {title} </div>}
				</div>
				<div className={`${css(styleSheet.tags, ...(styles || []))} ${className || ''}`}>
					{visibleTags.map(x => {
						return <Tag className={css(styleSheet.tag)} key={`contact-${contact.id}-tag-${x}`} tagValue={x} />;
					})}
					{extraTags.length > 0 ? (
						<TinyPopover
							isOpen={isOpen}
							anchor={
								<span
									className={css(baseStyleSheet.brandLink, styleSheet.tagMore)}
									onMouseEnter={() => setIsOpen(true)}
								>
									&nbsp;{`+${extraTags.length} more`}
								</span>
							}
							onRequestClose={() => setIsOpen(false)}
							autoCloseOtherPopoversOnHover={true}
							dismissOnOutsideAction={true}
						>
							<div className={css(styleSheet.extraTags)} onMouseLeave={() => setIsOpen(false)}>
								{extraTags.map(x => {
									return (
										<div key={x} className={css(styleSheet.extraTagsItem)}>
											<Tag className={css(styleSheet.tag)} key={`contact-${contact.id}-tag-${x}`} tagValue={x} />
										</div>
									);
								})}
							</div>
						</TinyPopover>
					) : null}
				</div>
				<div className={`${css(styleSheet.info, ...(styles || []))} ${className || ''}`}>
					{phone && <div className={css(styleSheet.title)}> {phone} </div>}
					{email && <div className={css(styleSheet.title)}> {email} </div>}
				</div>
				<div className={`${css(styleSheet.connections, ...(styles || []))} ${className || ''}`}>
					<div className={`${css(styleSheet.title, styleSheet.connectionsItemOwner)}`} key={contactOwner?.user?.id}>
						{contactOwner?.user ? <span>{getDisplayName(contactOwner.user)}</span> : null}
						<span className={css(styleSheet.title)}>{`${
							contactOwnerConnectionTypes ? ' (Owner, ' + contactOwnerConnectionTypes.join(', ') + ')' : ' (Owner)'
						}`}</span>
						<span>{`${otherContacts.length ? ',' : ''}`}</span>
					</div>
					<div>
						{otherContacts.map((x, i) => {
							const displayName = x?.user ? getDisplayName(x.user) : '';
							const addComma = i !== otherContacts.length - 1 && otherContacts.length > 1;
							const connectionTypesListDisplay = x?.connectionTypes
								? x.connectionTypes.sort((a, b) => a.localeCompare(b))
								: null;

							return (
								<span className={css(styleSheet.title)} key={x?.user?.id} title={displayName}>
									{`${displayName}${
										connectionTypesListDisplay ? ' (' + connectionTypesListDisplay?.join(', ') + ')' : ''
									}${addComma ? ',' : ''}`}
									{!!addComma && <span>&nbsp;</span>}
								</span>
							);
						})}
						{overflowConnectionCount > 0 && <span>&nbsp;{`+${overflowConnectionCount} more`}</span>}
					</div>
				</div>
			</div>
		</div>
	);
};

export const getVisibleConnections = (contact: Api.IContact) => {
	const contactConnections = contact.connections || [];
	// only show the first 2
	const visibleUsers = contactConnections
		.sort((a, b) => {
			if (a?.user?.id === contact.ownerId) {
				return -1;
			} else if (b?.user?.id === contact.ownerId) {
				return 1;
			}
			return 0;
		})
		.slice(0, 2);
	return visibleUsers;
};

export const PeopleRow = observer(PeopleRowInternal);
