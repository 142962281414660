import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import {
	ICreateCampaignRequest,
	ICreateSocialMediaPostRequest,
	ILocationState,
	TemplateOrTemplateFilter,
} from '../../../models';
import { Topics } from '../../../models/LocalNotificationTopics';
import { ILocalNotification } from '../../../models/LocalNotifications';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useFullscreenModal, useToaster, useUserSession } from '../../../models/hooks/appStateHooks';
import {
	invalidateAllTemplateCategorySocialQueries,
	invalidateSocialDrafts,
	useDeleteSocialPostMutation,
	useSocialAllCategoryTemplates,
	useSocialCategories,
	useSocialPostDraftsInfiniteQuery,
	useSocialSearchTemplates,
	useTemplateCategorySocialQuery,
	useTemplateDeleteMutation,
	useTemplateScopeMutation,
} from '../../../queries';
import { ISocialMediaPost, ITemplateCard, KnownCategories } from '../../../viewmodels/AppViewModels';
import { ConfirmationDialog, IConfirmationDialogOption } from '../../components/ConfirmationDialog';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { LocalNotificationObserver } from '../../components/LocalNotificationObserver';
import { MoreMenu, MoreMenuItem } from '../../components/MoreMenu';
import { EmailReportCard } from '../../components/campaigns/EmailReportCard';
import { OopsNotFoundSocialDraftsGraphic } from '../../components/svgs/graphics/OopsNotFoundSocialDraftsGraphic';
import { WarningIcon } from '../../components/svgs/icons/WarningIcon';
import { SocialCampaignTemplateCard } from '../../components/templateCards/SocialCampaignTemplateCard';
import { TemplateSharingConfirmation } from '../../components/templates/TemplateSharingConfirmation';
import { bs } from '../../styles/styles';
import { CampaignBrowser, useCampaignParams, useEnforceIndustryAndCategory } from '../CampaignBrowser';
import { BrowseAllInCategoryButton } from '../CampaignBrowser/presentation';
import { TabValues } from './presentation';
import { styleSheet } from './styles';

export const SocialMediaCampaignCards = observer(() => {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('SocialMediaCampaignCards');
	const { category, industry, search, setCampaignBrowserParam } = useCampaignParams();
	const categoriesQuery = useSocialCategories({
		industry,
		select: categories => {
			const browserCategories = categories.filter(
				c => c !== 'Archived' && c !== 'Uncategorized' && c !== 'HTML Newsletters' && c !== 'My Templates'
			);
			return [
				KnownCategories.All,
				KnownCategories.MyTemplates,
				KnownCategories.SocialMediaDrafts,
				...browserCategories,
			];
		},
	});
	const myDraftsQuery = useSocialPostDraftsInfiniteQuery({
		enabled: category === KnownCategories.SocialMediaDrafts || category === KnownCategories.All,
		userId: userSession.user.id,
	});
	const searchTemplatesQuery = useSocialSearchTemplates({
		enabled: !!search,
		industry,
		query: search,
	});
	const deleteSocialPostMutation = useDeleteSocialPostMutation({
		onSuccess: () => {
			invalidateSocialDrafts();
			toaster.push({
				message: 'Social media post deleted',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialPost-Error', error);
		},
	});
	useEnforceIndustryAndCategory({ setCampaignBrowserParam, tabUrlParam: TabValues.Social });
	const [draftTemplateToDelete, setDraftTemplateToDelete] = React.useState<Api.ISocialMediaPost | null>(null);
	const onViewSocialCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: `/social-media/post/create/from-template`,
				state: locationState,
			});
		} catch (error) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	const onViewCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	const onSocialMediaDraftClicked = async (postCard: ISocialMediaPost) => {
		try {
			const postVm = new Api.SocialMediaPostViewModel(userSession, postCard);
			await postVm.load();

			const locationState: ILocationState<Api.SocialMediaPostViewModel, any> = {
				viewModel: postVm,
			};

			fullscreenModal?.history.push({
				pathname: `/social-media/post/edit-post-draft/${postVm.id}`,
				state: locationState,
			});
		} catch (error) {
			errorMessages?.pushApiError(error);
			logApiError('LoadPost-Error', error);
		}
	};
	const handleDeleteConfirmationClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			deleteSocialPostMutation.mutate({ id: draftTemplateToDelete.id });
		}
		setDraftTemplateToDelete(null);
	};
	const renderLoading = () => {
		return (
			<div className={css(styleSheet.loadingContainer)}>
				<LoadingSpinner type='large' />
			</div>
		);
	};
	const renderSearchTemplates = () => {
		if (!search) {
			return;
		}
		if (searchTemplatesQuery.status !== 'success') {
			return renderLoading();
		}
		return (
			<div className={css(styleSheet.cards)}>
				<EmailReportCard isSocial={true} />
				{searchTemplatesQuery.data.length > 0 ? (
					<div className={css(bs.bgGray50, bs.pt5, bs.px7)}>
						<div className={css(bs.grid, bs.gap5, bs.mt5, styleSheet.socialDraftCards)}>
							{searchTemplatesQuery.data.map(card => {
								return (
									<SocialCampaignTemplateCard
										styles={[bs.wAuto, bs.m0]}
										card={card}
										key={card.id}
										onAssociatedTemplateClicked={() => onViewCampaign(card.associatedTemplates[0].id)}
										onCtaClicked={() => {
											onViewSocialCampaign(card.id);
										}}
									/>
								);
							})}
							<Waypoint bottomOffset='-200px' onEnter={() => myDraftsQuery.fetchNextPage()} />
						</div>
					</div>
				) : (
					<div className={css(styleSheet.noCampaigns)}>
						<OopsNotFoundSocialDraftsGraphic />
						<div>We did not find any social media drafts based on your search.</div>
						<div>Please try a different search.</div>
					</div>
				)}
			</div>
		);
	};
	const renderMyDrafts = () => {
		if (category !== KnownCategories.SocialMediaDrafts || search) {
			return;
		}
		if (myDraftsQuery.status !== 'success') {
			return renderLoading();
		}
		return (
			<div className={css(styleSheet.cards)}>
				<EmailReportCard isSocial={true} />
				{myDraftsQuery.data?.pages[0].values.length > 0 ? (
					<div className={css(bs.bgGray50, bs.pt5, bs.px7)}>
						<div className={css(bs.flex, bs.itemsCenter, bs.justifyBetween, bs.gap2)}>
							<h2 className={css(bs.pb1, bs.pl1, bs.capitalize, bs.textTitles)}>Social Media Drafts</h2>
							<BrowseAllInCategoryButton onClick={() => setCampaignBrowserParam({ category: KnownCategories.All })}>
								Browse All Campaigns
							</BrowseAllInCategoryButton>
						</div>
						<div className={css(bs.grid, bs.gap5, bs.mt5, styleSheet.socialDraftCards)}>
							{myDraftsQuery.data.pages.map(page => {
								return page.values.map(card => {
									return (
										<SocialCampaignTemplateCard
											styles={[bs.wAuto, bs.m0]}
											card={card}
											key={card.id}
											onAssociatedTemplateClicked={() =>
												onViewSocialCampaign(card?.templateReference?.templateId ?? '')
											}
											onCtaClicked={() => onSocialMediaDraftClicked(card)}
											contextMenu={
												<MoreMenu>
													<MoreMenuItem onClick={() => setDraftTemplateToDelete(card)}>Delete</MoreMenuItem>
												</MoreMenu>
											}
										/>
									);
								});
							})}
							<Waypoint bottomOffset='-200px' onEnter={() => myDraftsQuery.fetchNextPage()} />
						</div>
					</div>
				) : (
					<div className={css(styleSheet.noCampaigns)}>
						<OopsNotFoundSocialDraftsGraphic />
						<div>We did not find any social media drafts based on your search.</div>
						<div>Please try a different search.</div>
					</div>
				)}
			</div>
		);
	};
	const handleSearchChange = React.useCallback(
		(value: string) => {
			setCampaignBrowserParam({ search: value });
		},
		[setCampaignBrowserParam]
	);

	const onNotification = (notification: ILocalNotification<string>) => {
		if (notification.topic === Topics.EDIT_SOCIAL_POST || notification.topic === Topics.CREATE_SOCIAL_POST) {
			myDraftsQuery.refetch();
		}
	};

	return (
		<>
			<CampaignBrowser
				category={category}
				categories={categoriesQuery?.data ?? []}
				industry={industry}
				search={search}
				onSearchChange={handleSearchChange}
				onCategoryChange={value => setCampaignBrowserParam({ category: value })}
				onIndustryChange={value => setCampaignBrowserParam({ industry: value })}
			>
				{!search && category === KnownCategories.MyTemplates ? <CampaignBrowserSocialMyTemplates /> : null}
				{!search && category === KnownCategories.All ? <CampaignBrowserSocialAllTemplates /> : null}
				{renderSearchTemplates()}
				{renderMyDrafts()}
				{!search &&
				![KnownCategories.All, KnownCategories.MyTemplates, KnownCategories.SocialMediaDrafts].includes(category) ? (
					<CampaignBrowserCategoryTemplates />
				) : null}
			</CampaignBrowser>
			<ConfirmationDialog
				icon={<WarningIcon />}
				options={[
					{
						isDestructive: true,
						representedObject: true,
						title: 'Delete',
					},
					{
						isCancel: true,
						representedObject: false,
						title: 'Cancel',
					},
				]}
				title='Are you sure you want to delete this social media draft'
				modalProps={{
					isOpen: draftTemplateToDelete != null,
					onRequestClose: handleDeleteConfirmationClose,
				}}
			/>
			<LocalNotificationObserver onNotificationReceived={onNotification} topic={Topics.EDIT_SOCIAL_POST} />
			<LocalNotificationObserver onNotificationReceived={onNotification} topic={Topics.CREATE_SOCIAL_POST} />
		</>
	);
});

function CampaignBrowserSocialAllTemplates() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('CampaignBrowserSocialAllTemplates');
	const { industry } = useCampaignParams();
	const [templateIdToDelete, setTemplateIdToDelete] = React.useState<string | null>(null);
	const [templateToShare, setTemplateToShare] = React.useState<ITemplateCard | null>(null);
	const [draftTemplateToDelete, setDraftTemplateToDelete] = React.useState<Api.ISocialMediaPost | null>(null);
	const deleteTemplateMutation = useTemplateDeleteMutation({
		onSuccess: () => {
			invalidateAllTemplateCategorySocialQueries();
			toaster.push({
				message: 'Social media template deleted',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialTemplate-Error', error);
		},
	});
	const deleteSocialPostMutation = useDeleteSocialPostMutation({
		onSuccess: () => {
			invalidateSocialDrafts();
			toaster.push({
				message: 'Social media post deleted',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialPost-Error', error);
		},
	});
	const templateScopeMutation = useTemplateScopeMutation({
		onSuccess: () => {
			invalidateAllTemplateCategorySocialQueries();
			toaster.push({
				message: 'Template share status changed',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialTemplate-Error', error);
		},
	});
	const allCategoryTemplatesQuery = useSocialAllCategoryTemplates({
		industry,
	});
	const myTemplatesQuery = useTemplateCategorySocialQuery({
		categoryName: KnownCategories.Me,
		industry,
		pageSize: 8,
	});
	const myDraftsQuery = useSocialPostDraftsInfiniteQuery({
		userId: userSession.user.id,
		pageSize: 8,
	});
	const onSocialMediaDraftClicked = async (postCard: ISocialMediaPost) => {
		try {
			const postVm = new Api.SocialMediaPostViewModel(userSession, postCard);
			await postVm.load();
			const locationState: ILocationState<Api.SocialMediaPostViewModel, any> = {
				viewModel: postVm,
			};
			fullscreenModal?.history.push({
				pathname: `/social-media/post/edit-post-draft/${postVm.id}`,
				state: locationState,
			});
		} catch (error) {
			errorMessages?.pushApiError(error);
			logApiError('LoadPost-Error', error);
		}
	};
	const onViewCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	const onViewSocialCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/social-media/post/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	const handleDeleteDraftConfirmationClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			deleteSocialPostMutation.mutate({ id: draftTemplateToDelete.id });
		}
		setDraftTemplateToDelete(null);
	};
	const handleDeleteTemplateConfirmationClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			deleteTemplateMutation.mutate({ id: templateIdToDelete });
		}
		setTemplateIdToDelete(null);
	};
	const handleShareConfirmationClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			templateScopeMutation.mutate({
				scope: templateToShare.scope === Api.TemplateScope.Account ? Api.TemplateScope.User : Api.TemplateScope.Account,
				id: templateToShare.id,
			});
		}
		setTemplateToShare(null);
	};
	if (!allCategoryTemplatesQuery.isSuccess) {
		return (
			<div className={css(bs.flex, bs.itemsCenter, bs.justifyCenter, bs.pt5)}>
				<LoadingSpinner type='large' />
			</div>
		);
	}
	const categories = Object.keys(allCategoryTemplatesQuery.data).filter(
		c => ![KnownCategories.Featured, KnownCategories.SocialMediaDrafts, KnownCategories.Me].includes(c)
	) as KnownCategories[];
	return (
		<div className={css(styleSheet.cards)}>
			<EmailReportCard isSocial={true} />
			{allCategoryTemplatesQuery.data.Featured?.length ? (
				<div className={css(bs.bgBackground, bs.pt5, bs.px8, bs.flex, bs.flexCol, bs.gap2)}>
					<h2 className={css(bs.textTitles, bs.pb1, bs.pl1, bs.capitalize)}>Featured Cards</h2>
					<div className={css(bs.grid, bs.mt5, bs.gap5, styleSheet.socialDraftCards)}>
						{allCategoryTemplatesQuery.data.Featured.map(card => {
							return (
								<SocialCampaignTemplateCard
									styles={[bs.wAuto, bs.m0]}
									card={card}
									key={card.id}
									isFeatured={true}
									onAssociatedTemplateClicked={() => onViewCampaign(card.associatedTemplates[0].id)}
									onCtaClicked={() => {
										onViewSocialCampaign(card.id);
									}}
								/>
							);
						})}
					</div>
				</div>
			) : null}
			{myTemplatesQuery.data?.length ? (
				<div className={css(bs.bgBackground, bs.pt5, bs.px8, bs.flex, bs.flexCol, bs.gap2)}>
					<h2 className={css(bs.textTitles, bs.pb1, bs.pl1, bs.capitalize)}>My Templates</h2>
					<div className={css(bs.grid, bs.mt5, bs.gap5, styleSheet.socialDraftCards)}>
						{myTemplatesQuery.data.map(template => {
							return (
								<SocialCampaignTemplateCard
									styles={[bs.wAuto, bs.m0]}
									card={template}
									key={template.id}
									onAssociatedTemplateClicked={() => onViewCampaign(template.associatedTemplates[0].id)}
									onCtaClicked={() => {
										onViewSocialCampaign(template.id);
									}}
									contextMenu={
										template.creator?.id === userSession.user.id || Api.isAdmin(userSession.user) ? (
											<MoreMenu>
												<MoreMenuItem onClick={() => setTemplateIdToDelete(template.id)}>Delete</MoreMenuItem>
												<MoreMenuItem onClick={() => setTemplateToShare(template)}>
													{template.scope === Api.TemplateScope.Account ? 'Unshare' : 'Share'}
												</MoreMenuItem>
											</MoreMenu>
										) : null
									}
								/>
							);
						})}
					</div>
				</div>
			) : null}
			{myDraftsQuery.data?.pages?.[0]?.values?.length ? (
				<div className={css(bs.bgBackground, bs.pt5, bs.px8, bs.flex, bs.flexCol, bs.gap2)}>
					<h2 className={css(bs.textTitles, bs.pb1, bs.pl1, bs.capitalize)}>Drafts</h2>
					<div className={css(bs.grid, bs.mt5, bs.gap5, styleSheet.socialDraftCards)}>
						{myDraftsQuery.data.pages.map(page => {
							return page.values.map(card => {
								return (
									<SocialCampaignTemplateCard
										styles={[bs.wAuto, bs.m0]}
										card={card}
										key={card.id}
										onAssociatedTemplateClicked={() => onViewSocialCampaign(card?.templateReference?.templateId ?? '')}
										onCtaClicked={() => onSocialMediaDraftClicked(card)}
										contextMenu={
											<MoreMenu>
												<MoreMenuItem onClick={() => setDraftTemplateToDelete(card)}>Delete</MoreMenuItem>
											</MoreMenu>
										}
									/>
								);
							});
						})}
					</div>
				</div>
			) : null}
			{categories.map(c => {
				return (
					<div key={c} className={css(bs.bgBackground, bs.pt5, bs.px8, bs.flex, bs.flexCol, bs.gap2)}>
						<h2 className={css(bs.textTitles, bs.pb1, bs.pl1, bs.capitalize)}>{c} Cards</h2>
						<div className={css(bs.grid, bs.mt5, bs.gap5, styleSheet.socialDraftCards)}>
							{allCategoryTemplatesQuery.data[c].map(card => {
								return (
									<SocialCampaignTemplateCard
										styles={[bs.wAuto, bs.m0]}
										card={card}
										key={card.id}
										onAssociatedTemplateClicked={() => onViewCampaign(card.associatedTemplates[0].id)}
										onCtaClicked={() => {
											onViewSocialCampaign(card.id);
										}}
									/>
								);
							})}
						</div>
					</div>
				);
			})}
			<ConfirmationDialog
				icon={<WarningIcon />}
				options={[
					{
						isDestructive: true,
						representedObject: true,
						title: 'Delete',
					},
					{
						isCancel: true,
						representedObject: false,
						title: 'Cancel',
					},
				]}
				title='Are you sure you want to delete this social media draft'
				modalProps={{
					isOpen: draftTemplateToDelete != null,
					onRequestClose: handleDeleteDraftConfirmationClose,
				}}
			/>
			<ConfirmationDialog
				icon={<WarningIcon />}
				options={[
					{
						isDestructive: true,
						representedObject: true,
						title: 'Delete',
					},
					{
						isCancel: true,
						representedObject: false,
						title: 'Cancel',
					},
				]}
				title='Are you sure you want to delete this social media template'
				modalProps={{
					isOpen: templateIdToDelete != null,
					onRequestClose: handleDeleteTemplateConfirmationClose,
				}}
			/>
			<TemplateSharingConfirmation
				onRequestClose={handleShareConfirmationClose}
				show={templateToShare != null}
				template={templateToShare ?? undefined}
			/>
		</div>
	);
}

function CampaignBrowserSocialMyTemplates() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const toaster = useToaster();
	const { logApiError } = useEventLogging('CampaignBrowserSocialMyTemplates');
	const { industry, setCampaignBrowserParam } = useCampaignParams();
	const [templateIdToDelete, setTemplateIdToDelete] = React.useState<string | null>(null);
	const [templateToShare, setTemplateToShare] = React.useState<ITemplateCard | null>(null);
	const deleteTemplateMutation = useTemplateDeleteMutation({
		onSuccess: () => {
			invalidateAllTemplateCategorySocialQueries();
			toaster.push({
				message: 'Social media template deleted',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialTemplate-Error', error);
		},
	});
	const templateScopeMutation = useTemplateScopeMutation({
		onSuccess: () => {
			invalidateAllTemplateCategorySocialQueries();
			toaster.push({
				message: 'Template share status changed',
				type: 'successMessage',
			});
		},
		onError: error => {
			errorMessages.pushApiError(error);
			logApiError('DeleteSocialTemplate-Error', error);
		},
	});
	const myTemplatesQuery = useTemplateCategorySocialQuery({
		categoryName: 'me',
		enabled: industry != null,
		industry,
	});
	const handleDeleteConfirmationClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			deleteTemplateMutation.mutate({ id: templateIdToDelete });
		}
		setTemplateIdToDelete(null);
	};
	const handleShareConfirmationClose = (result?: IConfirmationDialogOption<boolean>, cancelled?: boolean) => {
		if (!cancelled && result?.representedObject) {
			templateScopeMutation.mutate({
				scope: templateToShare.scope === Api.TemplateScope.Account ? Api.TemplateScope.User : Api.TemplateScope.Account,
				id: templateToShare.id,
			});
		}
		setTemplateToShare(null);
	};
	const onViewCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	const onViewSocialCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/social-media/post/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	if (!myTemplatesQuery.isSuccess) {
		return (
			<div className={css(bs.flex, bs.itemsCenter, bs.justifyCenter, bs.pt5)}>
				<LoadingSpinner type='large' />
			</div>
		);
	}
	return (
		<div className={css(styleSheet.cards)}>
			<EmailReportCard isSocial={true} />
			<div className={css(bs.bgBackground, bs.pt5, bs.px8, bs.flex, bs.flexCol, bs.gap2)}>
				<div className={css(bs.flex, bs.itemsCenter, bs.justifyBetween)}>
					<h2 className={css(bs.textTitles, bs.pb1, bs.pl1, bs.capitalize)}>My Templates</h2>
					<BrowseAllInCategoryButton onClick={() => setCampaignBrowserParam({ category: KnownCategories.All })}>
						Browse All Campaigns
					</BrowseAllInCategoryButton>
				</div>
				<div className={css(bs.grid, bs.mt5, bs.gap5, styleSheet.socialDraftCards)}>
					{myTemplatesQuery.data.map(template => {
						return (
							<SocialCampaignTemplateCard
								styles={[bs.wAuto, bs.m0]}
								card={template}
								key={template.id}
								onAssociatedTemplateClicked={() => onViewCampaign(template.associatedTemplates[0].id)}
								onCtaClicked={() => {
									onViewSocialCampaign(template.id);
								}}
								contextMenu={
									template.creator?.id === userSession.user.id || Api.isAdmin(userSession.user) ? (
										<MoreMenu>
											<MoreMenuItem onClick={() => setTemplateIdToDelete(template.id)}>Delete</MoreMenuItem>
											<MoreMenuItem onClick={() => setTemplateToShare(template)}>
												{template.scope === Api.TemplateScope.Account ? 'Unshare' : 'Share'}
											</MoreMenuItem>
										</MoreMenu>
									) : null
								}
							/>
						);
					})}
				</div>
				<ConfirmationDialog
					icon={<WarningIcon />}
					options={[
						{
							isDestructive: true,
							representedObject: true,
							title: 'Delete',
						},
						{
							isCancel: true,
							representedObject: false,
							title: 'Cancel',
						},
					]}
					title='Are you sure you want to delete this social media template'
					modalProps={{
						isOpen: templateIdToDelete != null,
						onRequestClose: handleDeleteConfirmationClose,
					}}
				/>
				<TemplateSharingConfirmation
					onRequestClose={handleShareConfirmationClose}
					show={templateToShare != null}
					template={templateToShare ?? undefined}
				/>
			</div>
		</div>
	);
}

function CampaignBrowserCategoryTemplates() {
	const userSession = useUserSession();
	const fullscreenModal = useFullscreenModal();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('CampaignBrowserSocialMyTemplates');
	const { industry, category, setCampaignBrowserParam } = useCampaignParams();
	const categoryTemplatesQuery = useTemplateCategorySocialQuery({
		categoryName: category,
		enabled: industry != null && category != null,
		industry,
		onError: error => {
			if (error.systemCode === 404) {
				setCampaignBrowserParam({ category: KnownCategories.All });
			}
		},
		retry: (_, error) => error.systemCode !== 404,
	});
	const onViewCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateCampaignRequest<TemplateOrTemplateFilter>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/email/campaigns/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	const onViewSocialCampaign = async (templateId: string) => {
		try {
			const templates = new Api.TemplatesViewModel(userSession);
			const template = await templates.getByIdExpandedByLastUsedBy(templateId);

			const locationState: ILocationState<any, ICreateSocialMediaPostRequest<Api.ITemplate>> = {
				model: {
					context: { ...template },
					type: 'Template',
				},
			};

			fullscreenModal?.history.push({
				pathname: '/social-media/post/create/from-template',
				state: locationState,
			});
		} catch (error: any) {
			errorMessages?.pushApiError(error);
			logApiError('LoadCategories-Error', error);
		}
	};
	if (!categoryTemplatesQuery.isSuccess) {
		return (
			<div className={css(bs.flex, bs.itemsCenter, bs.justifyCenter, bs.pt5)}>
				<LoadingSpinner type='large' />
			</div>
		);
	}
	return (
		<div className={css(styleSheet.cards)}>
			<EmailReportCard isSocial={true} />
			<div className={css(bs.bgBackground, bs.pt5, bs.px8, bs.flex, bs.flexCol, bs.gap2)}>
				<div className={css(bs.flex, bs.itemsCenter, bs.justifyBetween)}>
					<h2 className={css(bs.textTitles, bs.pb1, bs.pl1, bs.capitalize)}>{category}</h2>
					<BrowseAllInCategoryButton onClick={() => setCampaignBrowserParam({ category: KnownCategories.All })}>
						Browse All Campaigns
					</BrowseAllInCategoryButton>
				</div>
				<div className={css(bs.grid, bs.mt5, bs.gap5, styleSheet.socialDraftCards)}>
					{categoryTemplatesQuery.data.map(template => {
						return (
							<SocialCampaignTemplateCard
								styles={[bs.wAuto, bs.m0]}
								card={template}
								key={template.id}
								onAssociatedTemplateClicked={() => onViewCampaign(template.associatedTemplates[0].id)}
								onCtaClicked={() => {
									onViewSocialCampaign(template.id);
								}}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
}
