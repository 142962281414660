import { StyleSheet } from 'aphrodite';
import { brandPrimaryActive, brandPrimaryHover, brandPrimary, header } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
	section: {
		marginTop: 16,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	tabViewContainer: {},
	tabViewContent: {},
	tabViewItem: {
		borderBottom: `1px solid #D3DBDE `,
		color: header,
		display: 'inline-flex',
		flexBasis: 'auto',
		fontSize: 14,
		height: 44,
		padding: '0 36px',
		textAlign: 'center',
		textTransform: 'uppercase',
		whiteSpace: 'nowrap',
	},
	tabViewSelectedItem: {
		borderBottom: `3px solid ${brandPrimary}`,
		color: brandPrimary,
	},
	tabViewSelectedTabIndicator: {
		display: 'none',
	},
	tabViewTabs: {
		borderBottom: 'none',
		display: 'flex',
	},
	quickActions: {
		display: 'flex',
		gap: 8,
		justifyContent: 'center',
	},
	quickActionButton: {
		':active': {
			background: brandPrimaryActive,
		},
		':hover': {
			background: brandPrimaryHover,
		},
		alignItems: 'center',
		background: brandPrimary,
		borderRadius: '50%',
		cursor: 'pointer',
		display: 'flex',
		height: 40,
		justifyContent: 'center',
		width: 40,
	},
});
