import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useEffect, useState } from 'react';
import * as React from 'react';
import AIAssistantLargeIconImageUrl from '../../../assets/aiAssistantIconLarge.png';
import { success, white } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { RefreshIcon } from '../../svgs/icons/RefreshIcon';
import { styleSheet } from './styles';
import { TextArea } from '../../../../web/components/TextArea';
import { ISelectOption, Select } from '../../../../web/components/Select';
import { useGetGeneratedReviewResponse, usePostGoogleBusinessReply } from '../../../../queries';

const PersonalityOptions: ISelectOption<Api.AIPersonalityType>[] = [
	Api.AIPersonalityType.Formal,
	Api.AIPersonalityType.Informal,
	Api.AIPersonalityType.Humorous,
].map(personality => ({
	dataContext: personality,
	id: personality,
	text: personality,
}));

export const AIContentGenerationWizardBackground = () => {
	return (
		<svg
			className={css(styleSheet.background)}
			width='100%'
			height='100%'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<rect width='100%' height='100%' fill='#00528C' />
			<mask
				height='100%'
				id='mask0_0_3013'
				maskUnits='userSpaceOnUse'
				style={{ maskType: 'luminance' }}
				width='100%'
				x='0'
				y='0'
			>
				<rect width='100%' height='100%' fill='white' />
			</mask>
			<g mask='url(#mask0_0_3013)'>
				<ellipse cx='468' cy='260.5' rx='504' ry='616.5' fill='url(#aiGoogleRadialGradient0)' />
				<ellipse cx='53.5' cy='36' rx='285.5' ry='349' fill='url(#aiGoogleRadialGradient1)' />
			</g>
			<defs>
				<radialGradient
					id='aiGoogleRadialGradient0'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(468 260.5) scale(526.132 643.573)'
				>
					<stop stopColor='#FF0085' stopOpacity='0.706649' />
					<stop offset='1' stopColor='#00528C' stopOpacity='0.01' />
				</radialGradient>
				<radialGradient
					id='aiGoogleRadialGradient1'
					cx='0'
					cy='0'
					r='1'
					gradientUnits='userSpaceOnUse'
					gradientTransform='translate(53.5 36) scale(298.037 364.326)'
				>
					<stop stopColor='#2A008F' stopOpacity='0.706649' />
					<stop offset='1' stopColor='#00528C' stopOpacity='0.01' />
				</radialGradient>
			</defs>
		</svg>
	);
};

interface IProps {
	review: Api.IGoogleBusinessReview;
	onGeneratingChanged: (value: boolean) => void;
	onPostSuccess?: () => void;
	onError?: () => void;
}

export const AIGoogleReviewResponse = ({ review, onGeneratingChanged, onPostSuccess, onError }: IProps) => {
	const [response, setResponse] = useState('');
	const [sessionId, setSessionId] = useState('');
	const [instructions, setInstructions] = useState('');
	const [personality, setPersonality] = useState<Api.AIPersonalityType>(null);
	const [hasGeneratedResponse, setHasGeneratedResponse] = useState(false);

	const reviewId = review.id;

	const getGeneratedReviewResponseMutation = useGetGeneratedReviewResponse({
		onError: () => {
			setHasGeneratedResponse(false);
			onError?.();
		},
		onSuccess: data => {
			setResponse(data.comment);
			setSessionId(data.sessionId);
		},
	});

	const postGoogleBusinessReplyMutation = usePostGoogleBusinessReply({
		onError,
		onSuccess: () => {
			setResponse('');
			setSessionId('');
			onPostSuccess?.();
		},
	});

	const isLoading = getGeneratedReviewResponseMutation.isLoading || postGoogleBusinessReplyMutation.isLoading;

	useEffect(() => {
		onGeneratingChanged(isLoading);
	}, [isLoading, onGeneratingChanged]);

	const onTryItAgainClicked = () => {
		setHasGeneratedResponse(false);
	};

	const onGenerateClicked = () => {
		setHasGeneratedResponse(true);

		getGeneratedReviewResponseMutation.mutate({
			personality: Api.AIPersonalityType[personality],
			prompt: instructions,
			reviewId,
		});
	};

	const onPostReplyClicked = () => {
		postGoogleBusinessReplyMutation.mutate({ comment: response, sessionId, reviewId });
	};

	const onPersonalityClicked = (option: ISelectOption, wasSelected: boolean) => {
		if (wasSelected) {
			setPersonality(option.dataContext);
		}
	};

	return (
		<div className={`${css(styleSheet.container)} ai-content-generation-wizard`}>
			<AIContentGenerationWizardBackground />

			<section className={css(styleSheet.assistantContainer)}>
				<img className={css(styleSheet.assistantIcon)} src={AIAssistantLargeIconImageUrl} />
				<div className={css(styleSheet.messageContainer)}>
					<p className={css(styleSheet.pill)}>Let AI write a response for you</p>
				</div>
			</section>

			{!hasGeneratedResponse && (
				<section className={css(styleSheet.instructionsContainer)}>
					<label htmlFor='create-google-review-ai-prompt-input'>Writing Instructions for the AI: </label>
					<TextArea
						className={css(styleSheet.textArea)}
						inputId='create-google-review-ai-prompt-input'
						value={instructions}
						onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInstructions(e.target.value)}
						rows={2}
					/>

					<div className={css(styleSheet.personalitySelector)}>
						<label>Personality</label>
						<Select
							styles={[styleSheet.personalitySelect]}
							onOptionClick={onPersonalityClicked}
							onRenderPlaceholder={() => 'Select Personality'}
							options={PersonalityOptions}
							selectedOption={PersonalityOptions.find(option => option.dataContext === personality)}
						/>
					</div>
				</section>
			)}

			{hasGeneratedResponse && (
				<section className={css(styleSheet.responseContainer)}>
					<label htmlFor='create-google-review-reply-input'>AI Response: </label>
					<TextArea
						className={css(styleSheet.textArea)}
						inputId='create-google-review-reply-input'
						value={response}
						onChange={e => setResponse(e.target.value)}
						rows={5}
					/>
				</section>
			)}

			<section className={css(styleSheet.footer)}>
				{!hasGeneratedResponse && (
					<>
						<footer className={css(baseStyleSheet.horizontalStack, styleSheet.footerButtons)}>
							<button className={css(styleSheet.generateButton)} disabled={isLoading} onClick={onGenerateClicked}>
								<span className={css(baseStyleSheet.horizontalStack)}>
									<RefreshIcon className={css(styleSheet.refreshIcon)} fillColor={white} />
									<span>Generate Post</span>
								</span>
							</button>
						</footer>
					</>
				)}

				{hasGeneratedResponse && (
					<>
						<footer className={css(baseStyleSheet.horizontalStack, styleSheet.footerButtons)}>
							<button className={css(styleSheet.postResponseButton)} onClick={onPostReplyClicked} disabled={isLoading}>
								<span>Post AI Response</span>
							</button>
							<button className={css(styleSheet.footerCloseButton)} disabled={isLoading} onClick={onTryItAgainClicked}>
								<span className={css(baseStyleSheet.horizontalStack)}>
									<RefreshIcon className={css(styleSheet.refreshIcon)} fillColor={white} hoverColor={success} />
									<span>Try Again</span>
								</span>
							</button>
						</footer>
					</>
				)}
			</section>
		</div>
	);
};
